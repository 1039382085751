<template lang="pug">
    header.main_header.font_smooth.fixed_header.scroll_header
        .column
            .menu_box
                .mobile_menu
                    .navigation
                        ul.flex_content-vertical.jc_center
                            li
                                router-link(to="/" alt="Inicio" @click.native="closeMobileMenu").nav_link
                                    span Inicio
                            li
                                router-link(to="/servicios" alt="Servicios" @click.native="closeMobileMenu").nav_link
                                    span Servicios
                            li
                                router-link(to="/proyectos" alt="Proyectos" @click.native="closeMobileMenu").nav_link
                                    span Proyectos
                            li
                                router-link(to="/nosotros" alt="Nosotros" @click.native="closeMobileMenu").nav_link
                                    span Nosotros
                            li
                                router-link(to="/contacto" alt="Contactanos" @click.native="closeMobileMenu").nav_link
                                    span Contacto
                button.mobile_btn
                    span.text--xs.text--ls-sm.text--uppercase Menú
                    i.bar.top
                    i.bar.middle
                    i.bar.bottom
            .logo_box.flex_content-vertical.jc_center.ai_center
                router-link(to="/" alt="Inicio").logo
                    svg(viewBox="0 0 224.6 58.7").logo-horizontal
                        use(xlink:href="#icon-logo_horizontal")
                    span.off_screen La Fragua, Estructuras y Grúas
            .nav_box
                nav.navigation
                    ul.flex_content-horizontal.ai_center.jc_center
                        li
                            router-link(to="/" alt="Inicio").nav_link
                                span Inicio
                        li
                            router-link(to="/servicios" alt="Servicios").nav_link
                                span Servicios
                        li
                            router-link(to="/proyectos" alt="Proyectos").nav_link
                                span Proyectos
                        li
                            router-link(to="/nosotros" alt="Nosotros").nav_link
                                span Nosotros
            .contact_box.flex_content-horizontal.ai_center.jc_flex-end
                router-link(to="/contacto" alt="Contactanos").btn.btn--base
                    span Contacto
</template>

<script>
export default {
    name: 'include_mainHeader',
    data() {
        return {
            event_type: '',
            menu: HTMLElement,
            button: HTMLElement,
            isOpen: false
        }
    },
    methods: {
        init() {
            this.event_type = this.isMobile() ? 'touchstart' : 'click'
            this.menu = this.$el.querySelector('.mobile_menu')
            this.menu.addEventListener(this.event_type, event => { event.stopPropagation() })
            this.button = this.$el.querySelector('.mobile_btn')
            this.button.addEventListener(this.event_type, this.handlingMobileMenu)
            this.scroll()
        },
        isMobile() {
            let userAgent = navigator.userAgent
            return userAgent.match(/IEMobile/i)
                || userAgent.match(/WPDesktop/i)
                || userAgent.match(/Android/i)
                || userAgent.match(/BlackBerry/i)
                || userAgent.match(/iPhone|iPad|iPod/i)
                || userAgent.match(/Opera Mini/i)
        },
        scroll() {
            let deltaScroll = 100
            let header = this.$el
            window.addEventListener('scroll', () => {
                this.closeMobileMenu()
                let scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : (
                    document.documentElement ||
                    document.body.parentNode ||
                    document.body
                ).scrollTop
                if (scrollTop >= deltaScroll) header.classList.add('scroll_active')
                else header.classList.remove('scroll_active')
            })
        },
        openMobileMenu() {
            this.$el.classList.add('mobile_menu-open')
            document.body.classList.add('no_scroll')
            document.addEventListener(this.event_type, this.handlingMobileMenu)
            this.isOpen = true
        },
        closeMobileMenu() {
            this.$el.classList.remove('mobile_menu-open')
            document.body.classList.remove('no_scroll')
            document.removeEventListener(this.event_type, this.handlingMobileMenu)
            this.isOpen = false
        },
        handlingMobileMenu(event) {
            event.stopPropagation()
            if (this.isOpen) {
                this.closeMobileMenu()
            } else {
                this.openMobileMenu()
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>