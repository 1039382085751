export default [
    {
        pk: '01',
        tags: ['armaduras', 'grúas', 'lámina de cubierta'],
        name: "Explanada Cenobio Moreno",
        client: "Secretaría de Obras Públicas de Michoacán",
        description: "Para este proyecto se optó por un material apropiado que permitiera disminuir el riesgo de posibles filtraciones por el tipo de lámina, creando así un domo con características duraderas.",
        place: "Cenobio Moreno, Mich.",
        date: "Marzo 2017",
        need: "Generación del espacio público para el poblado de Cenobio Moreno.",
        solution: "Construcción de armaduras de tubo y cubierta de lámina pintro tipo KR-18.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Generación del espacio público para el poblado de Cenobio Moreno.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Cenobio Moreno, armadura de tubo, lámina pintro tipo KR-18, Michoacán, Secretaría de Obras Públicas'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Proyecto Explanada Cenobio Moreno'},
            {vmid: 'idtn', itemprop:'description', content: 'Generación del espacio público para el poblado de Cenobio Moreno.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Proyecto Explanada Cenobio Moreno'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/01/explanada-cenobio-moreno'},
            {vmid: 'pdtn', property:'og:description', content: 'Generación del espacio público para el poblado de Cenobio Moreno.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Generación del espacio público para el poblado de Cenobio Moreno.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Proyecto Explanada Cenobio Moreno'}
        ],
        thumbnail: {
            description: "Explanada Cenobio Moreno",
            path: '/img/projects/01/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/01/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/01/blueprints/img_model1',
                format: 'jpg'
            }
        ],
        video: '',
        gallery: [
            {
                description: "Explanada Cenobio Moreno",
                path: '/img/projects/01/gallery/img_1',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '02',
        tags: ['armaduras', 'lámina de cubierta'],
        name: "Tienda Azteca Las Tijeras",
        client: "Tienda Azteca",
        description: "En este proyecto se eligió un material adecuado que permitiera crear una estructura con características duraderas, así como la maximización de espacio.",
        place: "Morelia, Mich.",
        date: "Agosto 2017",
        need: "Construcción de un espacio de almacenamiento para la Tienda Azteca en la colonia las Tijeras.",
        solution: "Se crearon armaduras a base de tubo de 29 metros de claro, con cubierta de lámina zintro KR-18.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Construcción de un espacio de almacenamiento para la Tienda Azteca en la colonia las Tijeras.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Tienda Azteca, armadura de tubo, lámina zintro KR-18, colonia las Tijeras'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Tienda Azteca Las Tijeras'},
            {vmid: 'idtn', itemprop:'description', content: 'Construcción de un espacio de almacenamiento para la Tienda Azteca en la colonia las Tijeras.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Tienda Azteca Las Tijeras'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/02/tienda-azteca-las-tijeras'},
            {vmid: 'pdtn', property:'og:description', content: 'Construcción de un espacio de almacenamiento para la Tienda Azteca en la colonia las Tijeras.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Construcción de un espacio de almacenamiento para la Tienda Azteca en la colonia las Tijeras.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Tienda Azteca Las Tijeras'}
        ],
        thumbnail: {
            description: "Armadura de Tienda Azteca",
            path: '/img/projects/02/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/02/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/02/blueprints/img_model1',
                format: 'jpg'
            },
            {
                description: "Model 2",
                path: '/img/projects/02/blueprints/img_model2',
                format: 'jpg'
            }
        ],
        video: '',
        gallery: [
            {
                description: "Armadura Tienda Azteca Las Tijeras",
                path: '/img/projects/02/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Armadura en proceso",
                path: '/img/projects/02/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Nave Tienda Azteca Las Tijeras",
                path: '/img/projects/02/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Armadura semicompleta Tienda Azteca",
                path: '/img/projects/02/gallery/img_4',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '03',
        tags: ['armaduras', 'lámina de cubierta', 'estructuras', 'grúas'],
        name: "Dulcería Don Vasco",
        client: "Don Vasco",
        description: "En este proyecto se construyó una nave industrial necesaria como ampliación de la fábrica de dulces, una opción práctica en términos de construcción.",
        place: "Morelia, Mich.",
        date: "Octubre 2017",
        need: "Edificación de nuevas instalaciones en la fábrica de dulces, en donde se requerían soportes estructurales adecuados.",
        solution: "Construcción de una nave industrial con marco rígido de 34 metros de claro a base de placa A-50 con cubierta de lámina pintro tipo RN-100/35.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Edificación de nuevas instalaciones en la fábrica de dulces.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Don Vasco, nave industrial, placa A-50, lámina pintro tipo RN-100/35'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Dulcería Don Vasco'},
            {vmid: 'idtn', itemprop:'description', content: 'Edificación de nuevas instalaciones en la fábrica de dulces.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Dulcería Don Vasco'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/03/dulceria-don-vasco'},
            {vmid: 'pdtn', property:'og:description', content: 'Edificación de nuevas instalaciones en la fábrica de dulces.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Edificación de nuevas instalaciones en la fábrica de dulces.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Dulcería Don Vasco'}
        ],
        thumbnail: {
            description: "Nave industrial Don Vasco",
            path: '/img/projects/03/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/03/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/03/blueprints/img_model1',
                format: 'jpg'
            }
        ],
        video: 'https://player.vimeo.com/video/314371942',
        gallery: [
            {
                description: "Grúa montando nave industrial",
                path: '/img/projects/03/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Nave industrial Dulcería Don Vasco",
                path: '/img/projects/03/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Colocación de lamina en nave industrial",
                path: '/img/projects/03/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Montaje nave industrial",
                path: '/img/projects/03/gallery/img_4',
                format: 'jpg'
            },
            {
                description: "Nave industrial en proceso",
                path: '/img/projects/03/gallery/img_5',
                format: 'jpg'
            },
            {
                description: "Nave industrial Dulcería Don Vasco",
                path: '/img/projects/03/gallery/img_6',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '04',
        tags: ['armaduras', 'lámina de cubierta', 'estructuras', 'grúas'],
        name: "Unidad Deportiva Ejercito de la Revolución",
        client: "Six Sigma",
        description: "En este proyecto se construyeron estructuras y armaduras laminadas cubriendo diferentes áreas deportivas y recreativas que garantizan la mayor durabilidad.",
        place: "Morelia, Mich.",
        date: "Noviembre 2017",
        need: "Mejoramiento de espacios deportivos para la unidad deportiva Ejercito de la Revolución.",
        solution: "Cubiertas de armaduras a base de perfiles HSS (High Speed Steel) y PTR (Perfil tubular estructural) con laminado pintro de RN-100/35.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Mejoramiento de espacios deportivos.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Six Sigma, armadura de HSS, armadura de PTR, lamina pintro de RN-100/35'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Unidad Deportiva Ejercito de la Revolución'},
            {vmid: 'idtn', itemprop:'description', content: 'Mejoramiento de espacios deportivos.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Unidad Deportiva Ejercito de la Revolución'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/04/unidad-deportiva-ejercito-de-la-revolucion'},
            {vmid: 'pdtn', property:'og:description', content: 'Mejoramiento de espacios deportivos.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Mejoramiento de espacios deportivos.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Unidad Deportiva Ejercito de la Revolución'}
        ],
        thumbnail: {
            description: "Armadura Unidad Deportiva Ejercito de la Revolución",
            path: '/img/projects/04/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/04/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/04/blueprints/img_model1',
                format: 'jpg'
            },
            {
                description: "Model 2",
                path: '/img/projects/04/blueprints/img_model2',
                format: 'jpg'
            },
            {
                description: "Model 3",
                path: '/img/projects/04/blueprints/img_model3',
                format: 'jpg'
            },
            {
                description: "Model 4",
                path: '/img/projects/04/blueprints/img_model4',
                format: 'jpg'
            }
        ],
        video: 'https://player.vimeo.com/video/312191614',
        gallery: [
            {
                description: "Colocación bases armadura",
                path: '/img/projects/04/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Montaje de armaduras en cancha",
                path: '/img/projects/04/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Armadura completa en cancha",
                path: '/img/projects/04/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Colocación de lamina sobre armadura",
                path: '/img/projects/04/gallery/img_4',
                format: 'jpg'
            },
            {
                description: "Montaje de lamina sobre armadura",
                path: '/img/projects/04/gallery/img_5',
                format: 'jpg'
            },
            {
                description: "Armadura Unidad Deportiva Ejercito de la Revolución",
                path: '/img/projects/04/gallery/img_6',
                format: 'jpg'
            },
            {
                description: "Montaje armadura elevada",
                path: '/img/projects/04/gallery/img_7',
                format: 'jpg'
            },
            {
                description: "Montaje de armaduras en cancha",
                path: '/img/projects/04/gallery/img_8',
                format: 'jpg'
            },
            {
                description: "Montaje de armaduras en cancha",
                path: '/img/projects/04/gallery/img_9',
                format: 'jpg'
            },
            {
                description: "Montaje de armaduras en cancha",
                path: '/img/projects/04/gallery/img_10',
                format: 'jpg'
            },
            {
                description: "Vista de armadura unidad deportiva",
                path: '/img/projects/04/gallery/img_11',
                format: 'jpg'
            },
            {
                description: "Armadura Unidad Deportiva Ejercito de la Revolución",
                path: '/img/projects/04/gallery/img_12',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '05',
        tags: ['estructuras', 'grúas', 'lamina'],
        name: "Universidad Montrer",
        client: "Universidad Montrer",
        description: "Para este proyecto se construyeron los soportes con el material adecuado que permite el máximo aprovechamiento de las instalaciones.",
        place: "Morelia, Mich.",
        date: "Enero 2018",
        need: "Ampliación del número de aulas en la Universidad Montrer.",
        solution: "Construcción de un nuevo edificio con aulas de 4 niveles con base de perfiles HSS (High Speed Steel) e IPR (“I” Perfil Regular) con entrepisos de losacero.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Ampliación del número de aulas en la Universidad.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Universidad Montrer, estructuras para edificio, estructura de HSS, estructura de PTR, losacero'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Universidad Montrer'},
            {vmid: 'idtn', itemprop:'description', content: 'Ampliación del número de aulas en la Universidad.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Universidad Montrer'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/05/universidad-montrer'},
            {vmid: 'pdtn', property:'og:description', content: 'Ampliación del número de aulas en la Universidad.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Ampliación del número de aulas en la Universidad.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Universidad Montrer'}
        ],
        thumbnail: {
            description: "Estructura edificio Universidad Montrer",
            path: '/img/projects/05/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/05/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/05/blueprints/img_model1',
                format: 'jpg'
            },
            {
                description: "Model 2",
                path: '/img/projects/05/blueprints/img_model2',
                format: 'jpg'
            },
            {
                description: "Model 3",
                path: '/img/projects/05/blueprints/img_model3',
                format: 'jpg'
            },
        ],
        video: '',
        gallery: [
            {
                description: "Colocación de bases de estructura",
                path: '/img/projects/05/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Estructura en proceso",
                path: '/img/projects/05/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Montado de laminas en estructura",
                path: '/img/projects/05/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Estrucura de edificio",
                path: '/img/projects/05/gallery/img_4',
                format: 'jpg'
            },
            {
                description: "Nave industrial en proceso",
                path: '/img/projects/05/gallery/img_5',
                format: 'jpg'
            },
            {
                description: "Estructura de edificio Universidad Montrer",
                path: '/img/projects/05/gallery/img_6',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '06',
        tags: ['estructuras', 'grúas', 'lamina'],
        name: "Puente Vial en la Av. Vicente Santa María",
        client: "Constructora Nacional de Michoacán",
        description: "Para este proyecto se creó un soporte de acero con la capacidad de resistir la circulación vehicular al formar un puente que conecte esta importante vialidad.",
        place: "Morelia, Mich.",
        date: "Mayo 2018",
        need: "Construcción de un puente que conecte vialidades para circulación vehicular.",
        solution: "Soportes para puente vehicular a base de perfiles elaborados con placa A-50 y perfiles IPR, con entrepiso de losacero.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Construcción de un puente para circulación vehicular.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Constructora Nacional de Michoacán, Soportes para puente vehicular, Soporte de placa A-50, losacero, IPR'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Puente Vial en la Av. Vicente Santa María'},
            {vmid: 'idtn', itemprop:'description', content: 'Construcción de un puente para circulación vehicular.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Puente Vial en la Av. Vicente Santa María'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/06/puente-vial-en-la-av-vicente-santa-maria'},
            {vmid: 'pdtn', property:'og:description', content: 'Construcción de un puente para circulación vehicular.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Construcción de un puente para circulación vehicular.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Puente Vial en la Av. Vicente Santa María'}
        ],
        thumbnail: {
            description: "Soporte de acero de puente vial",
            path: '/img/projects/06/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/06/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/06/blueprints/img_model1',
                format: 'jpg'
            }
        ],
        video: 'https://player.vimeo.com/video/314372776',
        gallery: [
            {
                description: "Grúas colocando bases",
                path: '/img/projects/06/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Base puente",
                path: '/img/projects/06/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Estructura puente",
                path: '/img/projects/06/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Colocación de estrucura",
                path: '/img/projects/06/gallery/img_4',
                format: 'jpg'
            },
            {
                description: "Puente vial en proceso",
                path: '/img/projects/06/gallery/img_5',
                format: 'jpg'
            },
            {
                description: "Estructura Puente Vial",
                path: '/img/projects/06/gallery/img_6',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '07',
        tags: ['armadura', 'grúas', 'lamina'],
        name: "Colegio Manuel Fulcheri",
        client: "Colegio Manuel Fulcheri",
        description: "En este proyecto se utilizaron armaduras para formar un domo con lámina de buena capacidad estructural y de desagüe.",
        place: "Zamora, Mich.",
        date: "Julio 2018",
        need: "Implementación de un resguardo en la intemperie en la zona de recreación del colegio.",
        solution: "Se crearon armaduras de 25 metros de largo a base de tubo cubierta con lámina pintro tipo RN-100/35.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Implementación de un resguardo en la intemperie en la zona de recreación del colegio.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Colegio Manuel Fulcheri, Zamora, Michoacán, armadura de tubo, lámina pintro tipo RN-100/35'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Colegio Manuel Fulcheri'},
            {vmid: 'idtn', itemprop:'description', content: 'Implementación de un resguardo en la intemperie en la zona de recreación del colegio.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Colegio Manuel Fulcheri'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/07/colegio-manuel-fulcheri'},
            {vmid: 'pdtn', property:'og:description', content: 'Implementación de un resguardo en la intemperie en la zona de recreación del colegio.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Implementación de un resguardo en la intemperie en la zona de recreación del colegio.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Colegio Manuel Fulcheri'}
        ],
        thumbnail: {
            description: "Domo Colegio Manuel Fulcheri",
            path: '/img/projects/07/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/07/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/07/blueprints/img_model1',
                format: 'jpg'
            },
            {
                description: "Model 2",
                path: '/img/projects/07/blueprints/img_model2',
                format: 'jpg'
            },
            {
                description: "Model 3",
                path: '/img/projects/07/blueprints/img_model3',
                format: 'jpg'
            }
        ],
        video: 'https://player.vimeo.com/video/314371821',
        gallery: [
            {
                description: "Colocación de armadura",
                path: '/img/projects/07/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Montaje armadura domo",
                path: '/img/projects/07/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Armadura domo",
                path: '/img/projects/07/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Domo vista superior",
                path: '/img/projects/07/gallery/img_4',
                format: 'jpg'
            },
            {
                description: "Domo vista inferior",
                path: '/img/projects/07/gallery/img_5',
                format: 'jpg'
            },
            {
                description: "Domo Colegio Fulcheri",
                path: '/img/projects/07/gallery/img_6',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '08',
        tags: ['estructuras', 'grúas', 'lamina'],
        name: "Tienda Colchas Carla",
        client: "Colchas Carla",
        description: "Este proyecto requería elementos en el soporte para la construcción del edificio, mismos soportes aportan un sistema estructural adecuado para la tienda y bodega.",
        place: "Morelia, Mich.",
        date: "Agosto 2018",
        need: "Ampliación de la tienda y bodega de Colchas Carla, en donde se requería de soportes adecuados que maximicé las instalaciones.",
        solution: "Se construyó un edificio con perfiles HSS (High Speed Steel) e IPR (“I” Perfil Regular) y un entrepiso metálico de losacero, que ofrece una gran seguridad en su estructura.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Ampliación de la tienda y bodega de Colchas Carla.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Colchas Carla, estructuras para edificio, estructura de HSS, estructura de IPR, losacero'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Tienda Colchas Carla'},
            {vmid: 'idtn', itemprop:'description', content: 'Ampliación de la tienda y bodega de Colchas Carla.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Tienda Colchas Carla'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/08/tienda-colchas-carla'},
            {vmid: 'pdtn', property:'og:description', content: 'Ampliación de la tienda y bodega de Colchas Carla.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Ampliación de la tienda y bodega de Colchas Carla.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Tienda Colchas Carla'}
        ],
        thumbnail: {
            description: "Estructura tienda Colchas Carla",
            path: '/img/projects/08/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/08/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/08/blueprints/img_model1',
                format: 'jpg'
            },
            {
                description: "Model 2",
                path: '/img/projects/08/blueprints/img_model2',
                format: 'jpg'
            }
        ],
        video: 'https://player.vimeo.com/video/314383084',
        gallery: [
            {
                description: "Colocación base de estructura",
                path: '/img/projects/08/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Vigas de estructura",
                path: '/img/projects/08/gallery/img_2',
                format: 'jpg'
            },
            {
                description: "Estructura desde el interior",
                path: '/img/projects/08/gallery/img_3',
                format: 'jpg'
            },
            {
                description: "Estructura en proceso",
                path: '/img/projects/08/gallery/img_4',
                format: 'jpg'
            },
            {
                description: "Estructura Colchas Carla",
                path: '/img/projects/08/gallery/img_5',
                format: 'jpg'
            },
            {
                description: "Estructura Colchas Carla",
                path: '/img/projects/08/gallery/img_6',
                format: 'jpg'
            }
        ]
    },
    {
        pk: '09',
        tags: ['armadura', 'grúas', 'lamina'],
        name: "Tridilosa Hospital General Uruapan",
        client: "Hospital General de Uruapan",
        description: "Este proyecto se construyeron una estructura tridimensional (Tridilosa) de concreto y acero, la cual resulta eficiente y ligera para este tipo de proyectos.",
        place: "Uruapan, Mich.",
        date: "Agosto 2018",
        need: "Proteger y   de la intemperie.",
        solution: "Construcción e implementación de Tridilosa a base de perfiles PTR (Perfil tubular estructural) y cubierta de policarbonato.",
        meta: [
            {vmid: 'dtn', name:'description', content: 'Construcción e implementación de Tridilosa para resguardar el acceso al Hospital General Uruapan.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, Hospital General de Uruapan, Uruapan, Michoacán, tridilosa de PTR, cubierta de policarbonato'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Tridilosa Hospital General Uruapan'},
            {vmid: 'idtn', itemprop:'description', content: 'Construcción e implementación de Tridilosa para resguardar el acceso al Hospital General Uruapan.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Tridilosa Hospital General Uruapan'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyecto/09/tridilosa-hospital-general-uruapan'},
            {vmid: 'pdtn', property:'og:description', content: 'Construcción e implementación de Tridilosa para resguardar el acceso al Hospital General Uruapan.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Construcción e implementación de Tridilosa para resguardar el acceso al Hospital General Uruapan.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Tridilosa Hospital General Uruapan'}
        ],
        thumbnail: {
            description: "Tridilosa Hospital General Uruapan",
            path: '/img/projects/09/img_thumbnail',
            format: 'jpg'
        },
        background: {
            path: '/img/projects/09/bg_header',
            format: 'jpg'
        },
        blueprints: [
            {
                description: "Model 1",
                path: '/img/projects/09/blueprints/img_model1',
                format: 'jpg'
            },
            {
                description: "Model 2",
                path: '/img/projects/09/blueprints/img_model2',
                format: 'jpg'
            },
            {
                description: "Model 3",
                path: '/img/projects/09/blueprints/img_model3',
                format: 'jpg'
            }
        ],
        video: 'https://player.vimeo.com/video/314387253',
        gallery: [
            {
                description: "Tridilosa Hospital General Uruapan",
                path: '/img/projects/09/gallery/img_1',
                format: 'jpg'
            },
            {
                description: "Tridilosa Hospital General Uruapan",
                path: '/img/projects/09/gallery/img_2',
                format: 'jpg'
            }
        ]
    }
]