import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const asyncScrolling = new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve({ x: 0, y: 0 })
  }, 500)
})

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      if (to.name.indexOf('modal') == -1) {
        if (from.name.indexOf('modal') == -1) {
          return asyncScrolling
        } else {
          document.body.classList.remove('modal_active')
          if (to.name != 'page_services') {
            return asyncScrolling
          }
        }
      } else {
        document.body.classList.add('modal_active')
        if (from.name == 'page_home') {
          return asyncScrolling
        }
      }
    }
  }
})
