<template lang="pug">
    section.know_us
        .column
            .grid.grid-phi
                div.flex_content-vertical.jc_center
                    .wrapper.flex_content-horizontal.jc_center
                        .frame_expereince
                            .frame-edge
                                .frame-wrapper
                                    span.text--font_heading.text--w800 {{ years }}
                                    p.text--xs.text--uppercase.text--ls-sm Años de experiencia #[br] con orgullo
                div
                    .wrapper
                        .sec_title
                            span 01
                            h3 Conocenos
                        .margin-bottom--xxl
                            h2.text--xxxl Calidad de Servicio desde 1990
                        p Somos una empresa dedicada a la fabricación y montaje de estructuras metálicas en la zona centro y occidente de la República Mexicana.
                        p Estructuras y Grúas La Fragua&reg; cuenta orgullosamente con {{ years }} años al servicio de nuestros clientes, ayudando a materializar cada una de sus ideas. Desde el primer día de proyecto buscamos brindar seguridad, calidad y economía a nuestros clientes.
</template>

<script>
export default {
  name: 'include_knowUs',
  data() {
    return {
      years: 0
    }
  },
  methods: {
    yearsDiff() {
      const today = new Date()
      const foundation = new Date(1990,0,1)
      return today.getFullYear() - foundation.getFullYear()
    }
  },
  mounted() {
    this.years = this.yearsDiff()
  }
}
</script>