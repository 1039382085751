import { render, staticRenderFns } from "./MainHeader.vue?vue&type=template&id=1c360805&lang=pug&"
import script from "./MainHeader.vue?vue&type=script&lang=js&"
export * from "./MainHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MainHeader.vue"
export default component.exports