<template lang="pug">
    section.call_to_action.contact
        .bg.image
        .column
            .grid.grid-phi
                div.flex_content-vertical.jc_center
                    .wrapper
                        .sec_title
                            span 01
                            h3 Contacto
                        .margin-bottom--xxl
                            h2.text--xxxl Hablemos de tu Proyecto
                        p Dejanos tus datos y te contactaremos lo más pronto posible.
                        p Al registrarse, acepta nuestros términos y política de privacidad y aceptas que podamos ponernos en contacto contigo.
                div.flex_content-vertical.jc_center
                    .wrapper.flex_content-horizontal.jc_center
                        include_contactForm
</template>

<script>
import ContactForm from '@/views/includes/ContactForm'

export default {
    name: 'include_callToAction',
    components: {
        include_contactForm: ContactForm
    }
}
</script>