import Vue from 'vue'
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, {
    locale: 'es',
    dictionary: {
        es: {
            messages: {
                email: field => `Tu ${ field } no es válido`,
                required : field => `Tu ${ field } está vacío`,
                digits: (field, length) => `Debe ser numérico y contener ${length} dígitos`
            }
        }
    }
})