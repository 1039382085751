<template lang="pug">
  .layout_page
    slot
    include_callToContact
    include_mainFooter
</template>

<script>
import CallToContact from '@/views/includes/CallToContact'
import MainFooter from '@/views/includes/MainFooter'

export default {
    name: 'layout_page',
    components: {
        include_callToContact: CallToContact,
        include_mainFooter: MainFooter
    }
}
</script>