<template lang="pug">
  #app
    svg.off_screen
      symbol(id="icon-logo_horizontal")
        title La Fragua, Estructuras y Grúas
        g
          g
            path(d="M25.3,5c-2.8,1.4-5.3,3.1-7.7,5.1c-3.8,3.1-7,6.9-9.3,11.1c-1,1.9-1.8,3.9-2.4,6l1.6,0c0.8-1.7,1.7-3.3,2.8-4.7c2.6-3.7,5.9-6.8,9.5-9.4c3-2.1,6.1-3.8,9.5-5L25.3,5z")
            path(d="M81.1,21.4c-2.3-4.3-5.5-8.1-9.2-11.2c-7.5-6.3-17.2-9.9-27.1-10c-6,0-11.6,1.2-17,3.6L32.2,7c4.2-1.3,8.2-1.9,12.6-2c8.8,0.1,17.5,3,24.8,8.1c3.6,2.6,6.9,5.7,9.5,9.4c1.1,1.5,2,3.1,2.8,4.7l1.6,0C82.9,25.3,82.1,23.3,81.1,21.4z")
            path(d="M43.8,31.1L31.7,40c-1.1,0.8-1.7,2-1.7,3.3l0,15.1l5.9,0l0-13.7c0-1,0.4-1.9,1-2.7l8.2-9.6L43.8,31.1z")
            path(d="M34.1,21.3l-18.8,9.1c-1.4,0.7-2.4,2.1-2.4,3.7l-0.1,24.3l9.2,0l0.1-20.7c0-1.2,0.5-2.3,1.4-3.1l12.8-11.1L34.1,21.3z")
            path(d="M22.7,2.6L21.2,4l28.1,28.6c0.8,0.8,1.2,1.8,1.2,2.9l-0.1,22.9l8.4,0L59,31.7c0-1.3-0.6-2.6-1.7-3.4L22.7,2.6z")
            path(d="M46.6,14.4L45,15.9l23.4,16.6c1.1,0.8,1.7,2,1.7,3.4l0,0l-0.1,22.6l7.7,0l0.1-24.8c0-1.5-0.8-3-2.2-3.7L46.6,14.4z")
            path(d="M-0.1,58.2c7.6-1.3,15.1-2,22.7-2.5c3.8-0.3,7.6-0.3,11.4-0.5c3.8-0.1,7.6-0.1,11.4-0.1c3.8,0.1,7.6,0.1,11.4,0.2c3.8,0.2,7.6,0.3,11.4,0.6c7.6,0.5,15.1,1.2,22.7,2.6l0,0.4l-45.4,0.1l-45.4-0.3L-0.1,58.2z")
          g
            path(d="M107,40.9l0,2.7c0,0.4-0.2,0.6-0.6,0.6l-10.4,0c-0.4,0-0.6-0.2-0.6-0.6l0-14.9c0-0.4,0.2-0.6,0.6-0.6l3.2,0c0.4,0,0.6,0.2,0.6,0.6l0,11.7l6.5,0C106.8,40.3,107,40.6,107,40.9z")
            path(d="M123.1,44.2l-3.3,0c-0.3,0-0.5-0.1-0.7-0.4l-0.9-2.3l-5.8,0l-0.9,2.3c-0.1,0.3-0.3,0.4-0.7,0.4l-3.3,0c-0.4,0-0.6-0.3-0.4-0.7l6.8-14.9c0.1-0.3,0.3-0.4,0.7-0.4l1.7,0c0.3,0,0.5,0.1,0.7,0.4l6.6,15C123.7,44,123.6,44.2,123.1,44.2zM113.5,38.2l3.7,0l-1.8-4.4L113.5,38.2z")
            path(d="M141.1,28.8l0,2.9c0,0.4-0.2,0.6-0.6,0.6l-6.6,0l0,3.2l5.5,0c0.4,0,0.6,0.2,0.6,0.6l0,2.9c0,0.4-0.2,0.6-0.6,0.6l-5.5,0l0,4.2c0,0.4-0.2,0.6-0.6,0.6l-3.2,0c-0.4,0-0.6-0.2-0.6-0.6l0-14.9c0-0.4,0.2-0.6,0.6-0.6l10.4,0C140.9,28.2,141.1,28.4,141.1,28.8z")
            path(d="M156.4,44.3l-3.7,0c-0.3,0-0.5-0.1-0.7-0.4l-2.4-3.7l-1.8,0l0,3.5c0,0.4-0.2,0.6-0.6,0.6l-3.1,0c-0.4,0-0.6-0.2-0.6-0.6l0-14.9c0-0.4,0.2-0.6,0.6-0.6l6.2,0c4.1,0,6.6,2.6,6.6,6.1c0,2.2-1.2,4-3,5l3,4.3C157,44,156.8,44.3,156.4,44.3z M147.8,36.5l2.1,0c1.6,0,2.6-1,2.6-2.3c0-1.3-0.9-2.4-2.6-2.4l-2.1,0L147.8,36.5z")
            path(d="M174,44.3l-3.3,0c-0.3,0-0.5-0.1-0.7-0.4l-0.9-2.3l-5.8,0l-0.9,2.3c-0.1,0.3-0.3,0.4-0.7,0.4l-3.3,0c-0.4,0-0.6-0.3-0.4-0.7l6.8-14.9c0.1-0.3,0.3-0.4,0.7-0.4l1.7,0c0.3,0,0.5,0.1,0.7,0.4l6.6,15C174.6,44.1,174.4,44.3,174,44.3zM164.4,38.3l3.7,0l-1.8-4.4L164.4,38.3z")
            path(d="M189.8,36.4l0,5.7c0,0.3-0.1,0.5-0.3,0.6c-0.7,0.6-2.7,1.8-6.2,1.8c-5,0-8.6-3.6-8.6-8.2c0-4.6,3.6-8.3,8.8-8.3c2.7,0,4.4,0.7,5.4,1.3c0.3,0.2,0.3,0.5,0.2,0.8l-1.4,2.5c-0.2,0.3-0.4,0.4-0.8,0.2c-0.8-0.5-1.7-0.8-3.3-0.8c-2.7,0-4.5,1.6-4.5,4.3c0,2.6,1.9,4.3,4.3,4.3c0.8,0,1.5-0.1,2.2-0.4l0-3.9c0-0.4,0.2-0.6,0.6-0.6l2.9,0C189.6,35.8,189.8,36,189.8,36.4z")
            path(d="M192.4,37.2l0-8.3c0-0.4,0.2-0.6,0.6-0.6l3.2,0c0.4,0,0.6,0.2,0.6,0.6l0,8.6c0,1.5,0.7,3.2,3,3.2c2.3,0,3.1-1.7,3.1-3.2l0-8.6c0-0.4,0.2-0.6,0.6-0.6l3.2,0c0.4,0,0.6,0.2,0.6,0.6l0,8.3c0,4.9-3,7.4-7.5,7.4C195.3,44.6,192.4,42.1,192.4,37.2z")
            path(d="M224,44.5l-3.3,0c-0.3,0-0.5-0.1-0.7-0.4l-0.9-2.3l-5.8,0l-0.9,2.3c-0.1,0.3-0.3,0.4-0.7,0.4l-3.3,0c-0.4,0-0.6-0.3-0.4-0.7l6.8-14.9c0.1-0.3,0.3-0.4,0.7-0.4l1.7,0c0.3,0,0.5,0.1,0.7,0.4l6.6,15C224.6,44.2,224.5,44.5,224,44.5zM214.4,38.5l3.7,0l-1.8-4.4L214.4,38.5z")
      symbol(id="icon-logo_vertical")
        title La Fragua, Estructuras y Grúas
        g
          g
            path(d="M32.2,5.8c-3.4,1.7-6.6,3.8-9.5,6.3c-4.6,3.8-8.6,8.4-11.4,13.7c-1.2,2.3-2.2,4.8-2.9,7.3l1.9,0c1-2,2.1-4,3.4-5.8c3.2-4.5,7.3-8.4,11.8-11.6c3.6-2.5,7.6-4.6,11.7-6.2L32.2,5.8z")
            path(d="M100.9,26c-2.8-5.3-6.7-9.9-11.3-13.8C80.3,4.5,68.3,0.1,56.2,0c-7.4,0-14.3,1.5-20.9,4.5l5.4,3.8c5.2-1.7,10.1-2.4,15.5-2.4C67,6,77.7,9.6,86.7,15.9c4.5,3.2,8.5,7.1,11.7,11.6c1.3,1.8,2.4,3.8,3.4,5.8l2,0C103.1,30.8,102.1,28.4,100.9,26z")
            path(d="M54.9,38L40,49c-1.3,1-2.1,2.5-2.1,4.1l-0.1,18.6l7.2,0l0-16.9c0-1.2,0.4-2.4,1.2-3.3l10.2-11.9L54.9,38z")
            path(d="M42.9,25.9L19.8,37.1c-1.8,0.9-2.9,2.6-2.9,4.6l-0.1,29.9l11.3,0l0.1-25.5c0-1.5,0.6-2.9,1.8-3.8l15.7-13.7L42.9,25.9z")
            path(d="M28.9,2.9l-1.8,1.8l34.6,35.2c0.9,1,1.5,2.3,1.5,3.6l-0.1,28.2l10.4,0l0.1-33.1c0-1.6-0.8-3.2-2.1-4.1L28.9,2.9z")
            path(d="M58.4,17.5l-2,1.8l28.8,20.5c1.3,1,2.1,2.5,2.1,4.2v0l-0.1,27.8l9.5,0l0.1-30.5c0-1.9-1-3.6-2.7-4.5L58.4,17.5z")
            path(d="M0.9,71.3c9.3-1.6,18.6-2.5,28-3.1c4.7-0.4,9.3-0.4,14-0.6c4.7-0.1,9.3-0.1,14-0.2c4.7,0.1,9.3,0.1,14,0.3c4.7,0.2,9.3,0.3,14,0.7c9.3,0.6,18.6,1.5,27.9,3.2l0,0.5l-55.9,0.1L0.9,71.8L0.9,71.3z")
          g
            path(d="M10.1,95.1l0,2.4c0,0.3-0.2,0.5-0.5,0.5l-9.1,0c-0.3,0-0.5-0.2-0.5-0.5l0-13.1c0-0.3,0.2-0.5,0.5-0.5l2.9,0c0.3,0,0.5,0.2,0.5,0.5l0,10.3l5.8,0C10,94.6,10.1,94.8,10.1,95.1z")
            path(d="M24.3,98l-2.9,0c-0.3,0-0.5-0.1-0.6-0.4l-0.8-2l-5.1,0l-0.8,2c-0.1,0.3-0.3,0.4-0.6,0.4l-2.9,0c-0.4,0-0.5-0.2-0.4-0.6l6-13.1c0.1-0.3,0.3-0.4,0.6-0.4l1.5,0c0.3,0,0.5,0.1,0.6,0.4l5.8,13.2C24.9,97.8,24.7,98,24.3,98zM15.9,92.7l3.2,0l-1.6-3.8L15.9,92.7z")
            path(d="M40.2,84.4l0,2.6c0,0.3-0.2,0.5-0.5,0.5l-5.8,0l0,2.8l4.8,0c0.3,0,0.5,0.2,0.5,0.5l0,2.6c0,0.3-0.2,0.5-0.5,0.5l-4.8,0l0,3.7c0,0.3-0.2,0.5-0.5,0.5l-2.9,0c-0.3,0-0.5-0.2-0.5-0.5l0-13.1c0-0.3,0.2-0.5,0.5-0.5l9.2,0C40,83.9,40.2,84.1,40.2,84.4z")
            path(d="M53.6,98l-3.2,0c-0.3,0-0.5-0.1-0.6-0.3l-2.1-3.3l-1.6,0l0,3.1c0,0.3-0.2,0.5-0.5,0.5l-2.8,0c-0.3,0-0.5-0.2-0.5-0.5l0-13.1c0-0.3,0.2-0.5,0.5-0.5l5.4,0c3.6,0,5.8,2.3,5.8,5.4c0,2-1,3.5-2.7,4.4l2.6,3.8C54.2,97.8,54,98.1,53.6,98z M46,91.2l1.9,0c1.4,0,2.3-0.9,2.3-2c0-1.2-0.8-2.1-2.3-2.1l-1.8,0L46,91.2z")
            path(d="M69.1,98.1l-2.9,0c-0.3,0-0.5-0.1-0.6-0.4l-0.8-2l-5.1,0l-0.8,2c-0.1,0.3-0.3,0.4-0.6,0.4l-2.9,0c-0.4,0-0.5-0.2-0.4-0.6l6-13.1c0.1-0.3,0.3-0.4,0.6-0.4l1.5,0c0.3,0,0.5,0.1,0.6,0.4l5.8,13.2C69.7,97.9,69.5,98.1,69.1,98.1zM60.7,92.8l3.2,0L62.3,89L60.7,92.8z")
            path(d="M83.1,91.1l0,5.1c0,0.2-0.1,0.4-0.2,0.5c-0.6,0.5-2.4,1.6-5.5,1.6c-4.4,0-7.5-3.2-7.5-7.2c0-4,3.2-7.3,7.8-7.3c2.4,0,3.9,0.6,4.8,1.2c0.3,0.2,0.3,0.4,0.2,0.7l-1.2,2.2c-0.2,0.3-0.4,0.4-0.7,0.2c-0.7-0.4-1.5-0.7-2.9-0.7c-2.4,0-3.9,1.4-3.9,3.8c0,2.3,1.7,3.8,3.8,3.8c0.7,0,1.3-0.1,1.9-0.3l0-3.5c0-0.3,0.2-0.5,0.5-0.5l2.5,0C82.9,90.6,83.1,90.8,83.1,91.1z")
            path(d="M85.3,91.8l0-7.3c0-0.3,0.2-0.5,0.5-0.5l2.8,0c0.3,0,0.5,0.2,0.5,0.5l0,7.6c0,1.3,0.7,2.8,2.6,2.8c2,0,2.7-1.5,2.7-2.8l0-7.6c0-0.3,0.2-0.5,0.5-0.5l2.8,0c0.3,0,0.5,0.2,0.5,0.5l0,7.3c0,4.3-2.6,6.5-6.6,6.5C87.9,98.3,85.3,96.1,85.3,91.8z")
            path(d="M113.2,98.2l-2.9,0c-0.3,0-0.5-0.1-0.6-0.4l-0.8-2l-5.1,0l-0.8,2c-0.1,0.3-0.3,0.4-0.6,0.4l-2.9,0c-0.4,0-0.5-0.2-0.4-0.6l6-13.1c0.1-0.3,0.3-0.4,0.6-0.4l1.5,0c0.3,0,0.5,0.1,0.6,0.4l5.8,13.2C113.7,98,113.6,98.2,113.2,98.2zM104.7,92.9l3.2,0l-1.6-3.8L104.7,92.9z")
    include_curtain
    include_mainHeader
    transition(:name="transitionName")
      keep-alive
        router-view
</template>

<script>
import Curtain from '@/views/includes/Curtain'
import MainHeader from '@/views/includes/MainHeader'
import ress from '@/plugins/ress'

export default {
  name: 'App',
  components: {
    include_curtain: Curtain,
    include_mainHeader: MainHeader
  },
  data() {
    return {
      transitionName: ""
    }
  },
  metaInfo() {
    return {
      title: '',
      titleTemplate: 'La Fragua | %s',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Nos dedicamos a la fabricación y montaje de estructuras metálicas en la zona centro y occidente de la República Mexicana.'},
        {vmid: 'kw', name:'keywords', content: 'La fragua, constructora, acero, fabricación de estructuras metálica, montaje de estructuras metálica, Estructuras y Grúas La Fragua, estructuras metálicas, Grúas, marcos para túneles, armaduras, estructuras, Michoacán, Morelia , andamios, puntales, barandales, registros, letreros espectaculares, letras monumentales, mezanines, tanques elevados, canalones, ventilas, puentes peatonales, parapetos, pergolados, pórticos, rejas, portones industriales, excavadora, losacero, retroexcavadora, construcción en metal, cubiertas de naves, bodegas, faldones, techumbres, misión, visión, alianzas de la fragua, hércules, prefabricaceros, conócenos, nosotros'},
        {vmid: 'inm', itemprop:'name', content: 'La Fragua | Tenemos todo para construir tu proyecto'},
        {vmid: 'idtn', itemprop:'description', content: 'Nos dedicamos a la fabricación y montaje de estructuras metálicas en la zona centro y occidente de la República Mexicana.'},
        {vmid: 'ptt', property:'og:title', content: 'La Fragua | Tenemos todo para construir tu proyecto'},
        {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/'},
        {vmid: 'pdtn', property:'og:description', content: 'Nos dedicamos a la fabricación y montaje de estructuras metálicas en la zona centro y occidente de la República Mexicana.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Nos dedicamos a la fabricación y montaje de estructuras metálicas en la zona centro y occidente de la República Mexicana.'},
        {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Tenemos todo para construir tu proyecto'},
        {itemprop:'image', content:'https://lafragua.mx/img/social.png'},
        {property:'fb:app_id', content:''},
        {property:'og:site_name', content:'La Fragua'},
        {property:'og:locale', content:'es_ES'},
        {property:'og:type', content:'website'},
        {property:'og:image', content:'https://lafragua.mx/img/social.png'},
        {name:'twitter:card', content:'summary_large_image'},
        {name:'twitter:site', content:''},
        {name:'twitter:image', content:'https://lafragua.mx/img/social.png'}
      ]
    }
  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent
      return userAgent.match(/IEMobile/i) || userAgent.match(/WPDesktop/i)
        || userAgent.match(/Android/i)
        || userAgent.match(/BlackBerry/i)
        || userAgent.match(/iPhone|iPad|iPod/i)
        || userAgent.match(/Opera Mini/i)
    }
  },
  created() {
    const deviceType = ress.deviceParser(navigator.userAgent)
    this.$store.commit('setDeviceType', deviceType)
  },
  beforeMount() {
    if (!this.isMobile()) {
      document.body.classList.add("no_touch")
    }
  },
  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? "page_transition-backward" : "page_transition-forward"
    }
  }
}
</script>

<style lang="stylus" src="@/assets/styles/main.styl"/>