export default [
    {
        pk: '01',
        name: "Estructuras",
        main: true,
        projects: true,
        type: 'Servicio',
        short_description: "Fabricamos estructuras para edificios, compuestas por columnas y vigas de perfiles comerciales o de dimensiones especiales fabricados en taller, con conexiones por sistema de tornillería o soldadura.",
        description: "<p>Las estructuras metálicas estables y resistentes se construyen con rapidez, permiten grandes claros y pesan menos que sus contrapartes de concreto.</p><p>Este sistema consiste en un conjunto de columnas, vigas principales y vigas secundarias unidas mediante conexiones soldadas o atornilladas. Las estructuras metálicas normalmente presentan losas de entrepisos hechas con losacero unidas con conectores de cortante.</p><p>Este sistema es idóneo para edificios de oficinas, locales comerciales, departamentos, hospitales y estacionamientos.</p><p>Usamos perfiles comerciales tipo HSS, IPR o PTR, así como perfiles especiales hechos de placa.</p>",
        img: {
            description: "Estructura metálica para tienda y almacen de Colchas Carla",
            path: '/img/services/structures/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Fabricamos estructuras para edificios, compuestas por columnas y vigas de perfiles comerciales o de dimensiones especiales.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, estructuras para edificios, estructuras metálicas, acero, HSS, IPR, PTR, estructuras para oficinas, estructuras para locales, estructuras para hospitales, estructuras'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Estructuras'},
            {vmid: 'idtn', itemprop:'description', content: 'Fabricamos estructuras para edificios, compuestas por columnas y vigas de perfiles comerciales o de dimensiones especiales.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Estructuras'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/01/estructuras'},
            {vmid: 'pdtn', property:'og:description', content: 'Fabricamos estructuras para edificios, compuestas por columnas y vigas de perfiles comerciales o de dimensiones especiales.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Fabricamos estructuras para edificios, compuestas por columnas y vigas de perfiles comerciales o de dimensiones especiales.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Estructuras'}
        ]
    },
    {
        pk: '02',
        name: "Grúas",
        main: true,
        projects: false,
        type: 'Renta',
        short_description: "Contamos con grúas telescópicas, grúas titán y grúas articuladas con operadores calificados y certificados, por lo que ofrecemos un trabajo rápido y seguro. La capacidad de carga de nuestras grúas va desde 5 toneladas, hasta 200 toneladas. Se renta por horas, días, meses o maniobra.",
        description: "",
        externalLink: 'https://gruashercules.mx/maquinaria',
        img: {
            description: "Grúa de 45 toneladas con 40 metros de pluma y con 10 metros de extensión",
            path: '/img/services/cranes/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Contamos con servicio de grúas con capacidad desde 5 hasta 200 toneladas.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, grúas, grúas telescópicas, grúas titán, grúas articuladas, grúas de carga, grúas de 4 toneladas, grúas de 200 toneladas'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Grúas'},
            {vmid: 'idtn', itemprop:'description', content: 'Contamos con servicio de grúas con capacidad desde 5 hasta 200 toneladas.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Grúas'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/02/gruas'},
            {vmid: 'pdtn', property:'og:description', content: 'Contamos con servicio de grúas con capacidad desde 5 hasta 200 toneladas.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Contamos con servicio de grúas con capacidad desde 5 hasta 200 toneladas.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Grúas'}
        ]
    },
    {
        pk: '03',
        name: "Armaduras",
        main: true,
        projects: true,
        type: 'Servicio',
        short_description: "Fabricamos armaduras rectas, triangulares o en arco, bidimensionales o tridimensionales. Ideales para cubrir grandes claros, como son bodegas, techumbres, cubiertas, canchas, albercas, naves industriales, etc.",
        description: "<p>Elementos estructurales ideales para cubrir grandes claros con poco peso y gran resistencia basado en un conjunto de triángulos. Este sistema es perfecto para librar grandes claros en bodegas, techumbres, cubiertas, canchas, albercas, naves industriales, etc.</p><p>Las armaduras normalmente presentan techado a base de lámina RN-100/35 o KR-18. Usamos perfiles comerciales tipo ángulo, PTR, tubo o montén.</p>",
        img: {
            description: "Armadura para la unidad deportiva Ejercito de la Revolución",
            path: '/img/services/frames/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Fabricamos armaduras rectas, triangulares o en arco, bidimensionales o tridimensionales.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, armaduras rectas, armaduras triangulares, armaduras en arco, armaduras bidimensionales, armaduras tridimensionales, armaduras de PTR, armaduras de tubo, armaduras de montén'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Armaduras'},
            {vmid: 'idtn', itemprop:'description', content: 'Fabricamos armaduras rectas, triangulares o en arco, bidimensionales o tridimensionales.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Armaduras'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/03/armaduras'},
            {vmid: 'pdtn', property:'og:description', content: 'Fabricamos armaduras rectas, triangulares o en arco, bidimensionales o tridimensionales.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Fabricamos armaduras rectas, triangulares o en arco, bidimensionales o tridimensionales.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Armaduras'}
        ]
    },
    // {
    //     pk: '04',
    //     name: "Marcos para Túneles",
    //     main: false,
    //     projects: false,
    //     type: 'Servicio',
    //     short_description: "Fabricamos marcos metálicos a medida requerida por nuestros clientes para su utilización en túneles, usando perfiles comerciales o de dimensiones especiales fabricados en taller.",
    //     description: "Fabricamos marcos metálicos a medida requerida por nuestros clientes para su utilización en túneles, usando perfiles comerciales o de dimensiones especiales fabricados en taller. Mediante mano de obra calificada y certificada, soldadura de microalambre, perfiles comerciales tipo IPR, o perfiles especiales hechos de placa creamos las figuras de marco de cualquier proyecto.",
    //     img: {
    //         description: "Marco metálico de túnel",
    //         path: '/img/services/tunnels/img_base',
    //         format: 'jpg'
    //     },
    //     meta: [
    //         {vmid: 'dtn', name:'description', content: 'Fabricamos marcos metálicos a la medida para su utilización en túneles.'},
    //         {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, marcos metálicos, marcos para túneles, marcos de IPR, marcos de placa'},
    //         {vmid: 'inm', itemprop:'name', content: 'La Fragua | Marcos para Túneles'},
    //         {vmid: 'idtn', itemprop:'description', content: 'Fabricamos marcos metálicos a la medida para su utilización en túneles.'},
    //         {vmid: 'ptt', property:'og:title', content: 'La Fragua | Marcos para Túneles'},
    //         {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/04/marcos-para-tuneles'},
    //         {vmid: 'pdtn', property:'og:description', content: 'Fabricamos marcos metálicos a la medida para su utilización en túneles.'},
    //         {vmid: 'tdtn', name:'twitter:description', content: 'Fabricamos marcos metálicos a la medida para su utilización en túneles.'},
    //         {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Marcos para Túneles'}
    //     ]
    // },
    {
        pk: '05',
        name: "Losacero",
        main: false,
        projects: false,
        type: 'Fabricamos',
        short_description: "Fabricamos lámina tipo Losacero a medidas especiales para entrepisos metálicos.",
        description: "<p>La losacero es un sistema de entrepiso metálico para realizar en conjunto con el concreto, losas de entrepiso y azotea.</p><p>La losacero tiene tres funciones: durante la construcción funciona como cimbra para el colado de concreto, y una vez unida al concreto del colado, trabaja como refuerzo positivo por flexión y también como resistencia para cargas horizontales.</p><p>Contamos con máquina roladora para la fabricación de losacero tipo sección 4 a medida, en diferentes calibres, así como mano de obra capacitada para su correcta colocación.</p>",
        img: {
            description: "Roladora de lámina KR-18",
            path: '/img/services/losacero/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Fabricamos lámina tipo Losacero para entrepisos metálicos.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, losacero, lamina tipo losacero, entrepisos metálicos'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Losacero'},
            {vmid: 'idtn', itemprop:'description', content: 'Fabricamos lámina tipo Losacero para entrepisos metálicos.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Losacero'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/05/losacero'},
            {vmid: 'pdtn', property:'og:description', content: 'Fabricamos lámina tipo Losacero para entrepisos metálicos.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Fabricamos lámina tipo Losacero para entrepisos metálicos.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Losacero'}
        ]
    },
    {
        pk: '06',
        name: "Lámina de Cubierta",
        main: false,
        projects: false,
        type: 'Fabricamos',
        short_description: "Fabricamos lámina a medidas especiales tipo RN-100/35 y KR-18 para laminado de techos y faldones en acabados pintro y zintro.",
        description: "<p>Perfectas para usarse en cubiertas de naves, bodegas, faldones y techumbres.</p><p>Contamos con máquina roladora para la fabricación de lámina tipo KR-18 y RN-100/35 a medida, acabado pintro o zintro en diferentes calibres, así como mano de obra calificada para su correcta colocación.</p>",
        img: {
            description: "",
            path: '/img/services/sheet/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Fabricamos lámina tipo RN-100/35 y KR-18 para laminado de techos y faldones en acabados pintro y zintro.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, lamina de cubierta, lamina tipo RN-100/35, lamina tipo KR-18 laminado de techo, laminado de faldon, lamina con acabado pintro, lamina con acabado zintro'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Lámina de Cubierta'},
            {vmid: 'idtn', itemprop:'description', content: 'Fabricamos lámina tipo RN-100/35 y KR-18 para laminado de techos y faldones en acabados pintro y zintro.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Lámina de Cubierta'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/06/lamina-de-cubierta'},
            {vmid: 'pdtn', property:'og:description', content: 'Fabricamos lámina tipo RN-100/35 y KR-18 para laminado de techos y faldones en acabados pintro y zintro.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Fabricamos lámina tipo RN-100/35 y KR-18 para laminado de techos y faldones en acabados pintro y zintro.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Lámina de Cubierta'}
        ]
    },
    {
        pk: '07',
        name: "Generadores Eléctricos",
        main: false,
        projects: false,
        type: 'Renta',
        short_description: "Disponemos de generadores eléctricos de 65 hasta 125 KVA, indispensables en los trabajos de construcción para contar con electricidad en cualquier parte.",
        description: "",
        img: {
            description: "",
            path: '/img/services/generators/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Disponemos de generadores eléctricos de 65 hasta 125 KVA, para tener electricidad en cualquier parte.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua. Morelia, Generadores eléctricos, renta de generador eléctrico, generador eléctrico de 65 KVA, generador eléctrico de 125 KVA'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Generadores Eléctricos'},
            {vmid: 'idtn', itemprop:'description', content: 'Disponemos de generadores eléctricos de 65 hasta 125 KVA, para tener electricidad en cualquier parte.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Generadores Eléctricos'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/07/generadores-electricos'},
            {vmid: 'pdtn', property:'og:description', content: 'Disponemos de generadores eléctricos de 65 hasta 125 KVA, para tener electricidad en cualquier parte.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Disponemos de generadores eléctricos de 65 hasta 125 KVA, para tener electricidad en cualquier parte.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Generadores Eléctricos'}
        ]
    },
    {
        pk: '08',
        name: "Maquinaria para construcción",
        main: false,
        projects: false,
        type: 'Renta',
        short_description: "Contamos con excavadora, retroexcavadora y maquinaria necesarias para la construcción, usadas en nuestros proyectos y para renta; ya sea por horas, meses o años dependiendo de la necesidad de nuestros clientes.",
        description: "",
        externalLink: 'https://gruashercules.mx/maquinaria/excavadoras',
        img: {
            description: "Excavadora Hyundai R220LC-9S",
            path: '/img/services/machinery/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'Contamos con excavadora, retroexcavadora y más.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, maquinaria para construcción, excavadora, retroexcavadora, renta de excavadora, renta de retroexcavadora'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Maquinaria para construcción'},
            {vmid: 'idtn', itemprop:'description', content: 'Contamos con excavadora, retroexcavadora y más.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Maquinaria para construcción'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/08/maquinaria-para-construccion'},
            {vmid: 'pdtn', property:'og:description', content: 'Contamos con excavadora, retroexcavadora y más.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'Contamos con excavadora, retroexcavadora y más.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Maquinaria para construcción'}
        ]
    },
    {
        pk: '09',
        name: "Otros Servicios",
        main: false,
        projects: false,
        type: 'Servicio',
        short_description: "También fabricamos otros tipos de proyectos, adaptándonos a las necesidades de nuestros clientes, ya que contamos con una amplia experiencia y equipamiento para realizar todo tipo de soluciones en construcción con acero.",
        description: "<p>Nos adaptamos a otro tipo de proyectos que nuestros clientes requieren, como son:</p><ul class='basic_list st-circle sp-inside'><li><span>Puentes peatonales</span></li><li><span>Marcos para túneles</span></li><li><span>Mezanines</span></li><li><span>Parapetos</span></li><li><span>Pergolados</span></li><li><span>Tanques elevados</span></li><li><span>Letreros espectaculares</span></li><li><span>Andamios</span></li><li><span>Puntales</span></li><li><span>Y muchos más</span></li></ul>",
        img: {
            description: "",
            path: '/img/services/others/img_base',
            format: 'jpg'
        },
        meta: [
            {vmid: 'dtn', name:'description', content: 'También fabricamos otros tipos de proyectos, adaptándonos a las necesidades de nuestros clientes, ya que contamos con una amplia experiencia y equipamiento para realizar todo tipo de soluciones en construcción con acero.'},
            {vmid: 'kw', name:'keywords', content: 'La fragua, Morelia, andamios, puntales, barandales, registros, letreros espectaculares, letras monumentales, mezanines, tanques elevados, canalones, ventilas, puentes peatonales, parapetos, pergolados, pórticos, rejas, portones industriales'},
            {vmid: 'inm', itemprop:'name', content: 'La Fragua | Otros'},
            {vmid: 'idtn', itemprop:'description', content: 'También fabricamos otros tipos de proyectos, adaptándonos a las necesidades de nuestros clientes, ya que contamos con una amplia experiencia y equipamiento para realizar todo tipo de soluciones en construcción con acero.'},
            {vmid: 'ptt', property:'og:title', content: 'La Fragua | Otros'},
            {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/servicios/09/otros'},
            {vmid: 'pdtn', property:'og:description', content: 'También fabricamos otros tipos de proyectos, adaptándonos a las necesidades de nuestros clientes, ya que contamos con una amplia experiencia y equipamiento para realizar todo tipo de soluciones en construcción con acero.'},
            {vmid: 'tdtn', name:'twitter:description', content: 'También fabricamos otros tipos de proyectos, adaptándonos a las necesidades de nuestros clientes, ya que contamos con una amplia experiencia y equipamiento para realizar todo tipo de soluciones en construcción con acero.'},
            {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Otros'}
        ]
    }
]