import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import services from './services'
import projets from './projects'

export default new Vuex.Store({
  state: {
    deviceType: '',
    curtain_shown: false,
    services: services,
    projects: projets
  },
  getters: {
    isCurtainShown(state) {
      return state.curtain_shown
    },
    allServices(state) {
      return state.services
    },
    mainServices(state) {
      return state.services.filter(service => service.main)
    },
    servicesWithProjects(state) {
      return state.services.filter(service => service.projects)
    },
    allProjects(state) {
      return state.projects
    },
    singleService: state => pk => {
      return state.services.find(service => service.pk === pk)
    },
    singleProject: state => pk => {
      return state.projects.find(project => project.pk === pk)
    }
  },
  mutations: {
    setDeviceType(state, deviceType) {
      state.deviceType = deviceType
    },
    desactiveCurtain(state) {
      state.curtain_shown = true
    }
  },
  actions: {

  }
})
