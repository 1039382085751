import Home from '@/views/pages/Home'

// route level code-splitting
// this generates a separate chunk ([ChunkName].[hash].js) for this route
// which is lazy-loaded when the route is visited.
const loadView = view => {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/pages/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'page_home',
    component: Home
  },
  {
    path: '/servicios',
    name: 'page_services',
    component: loadView("Services"),
    children: [
      {
        name: 'modal_service',
        path: ':pk/:name',
        component: loadView("Service"),
        props: true
      }
    ]
  },
  {
    path: '/proyectos',
    name: 'page_projects',
    component: loadView("Projects")
  },
  {
    path: '/proyecto/:pk/:name',
    name: 'landpage_project',
    component: loadView("Project"),
    props: true
  },
  {
    path: '/nosotros',
    name: 'page_about',
    component: loadView("About")
  },
  {
    path: '/contacto',
    name: 'page_contact',
    component: loadView("Contact")
  }
]

export default routes