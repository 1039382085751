<template lang="pug">
  .form_container
    form(@submit.prevent="validateBeforeSubmit")
      fieldset
        legend.off_screen Formulario de contacto
        .field
          label(for="enterprise_1").tag
            span Nombre de la Empresa
          .input
            input(type="text" name="enterprise" id="enterprise_1"
              v-model="enterprise"
              v-validate="'required'"
              :class="{'invalid': errors.has('enterprise') }"
              data-vv-as="Empresa")
            span(v-show="errors.has('enterprise')").help.alert {{ errors.first('enterprise') }}
        .field
          label(for="email_1").tag
            span Correo Electrónico
          .input
            input(type="text" name="email" id="email_1"
              v-model="email"
              v-validate="'required|email'"
              :class="{'invalid': errors.has('email') }"
              data-vv-as="Correo")
            span(v-show="errors.has('email')").help.alert {{ errors.first('email') }}
        .field
          label(for="phone_1").tag
            span Número de Teléfono
          .input
            input(type="text" name="phone" id="phone_1"
              v-model="phone"
              v-validate="'required|digits:10'"
              :class="{'invalid': errors.has('phone') }"
              data-vv-as="Teléfono")
            span(v-show="errors.has('phone')").help.alert {{ errors.first('phone') }}
      .actions
        button(type="submit" ref="submitButton").btn.btn--secondary.submit.flex_content-horizontal.jc_center
          span.text--uppercase.text--ls-sm Enviar Mensaje
    .form_event
      .feedback-loading.flex_content-horizontal.jc_center.ai_center
        i
        span.text--xs.text--uppercase.text--ls-sm Enviando...
      .feedback-success.flex_content-horizontal.jc_center.ai_center
        i
        span.text--xs.text--uppercase.text--ls-sm Enviado
      .feedback-error.flex_content-horizontal.jc_center.ai_center
        i
        span.text--xs.text--uppercase.text--ls-sm Error de envío
</template>

<script>
import emailjs from 'emailjs-com'

export default {
  name: 'include_contactForm',
  data() {
    return {
      enterprise: "",
      email: "",
      phone: ""
    }
  },
  methods: {
    cleanForm() {
      this.enterprise = ""
      this.email = ""
      this.phone = ""
      this.$nextTick(() => this.$validator.reset())
      setTimeout(() => { this.$el.classList.remove("success") }, 3000)
    },
    send() {
      this.$el.classList.add("loading")

      let templateParams = {
        to_email: 'contacto@lafragua.mx',
        to_name: 'La Fragua',
        from_name: 'Sitio Web La Fragua',
        enterprise: this.enterprise,
        email: this.email,
        phone: this.phone
      }

      emailjs.send('service_6v6q7hq', 'template_m2b9zmn', templateParams, 'user_IXiq6RrMjLKF7FnaxctcX')
      .then(result => {
        console.log("SUCCESS!", result.status, result.text)
        this.$el.classList.remove("loading")
        this.$el.classList.add("success")
        this.cleanForm()
      }, error => {
        console.error("FAILED...", error)
        this.$el.classList.remove("loading")
        this.$el.classList.add("error")
      })
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.send()
          return
        }
      }).catch(error => console.error("VALIDATE:", error))
    }
  }
}
</script>
