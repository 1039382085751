import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/veeValidate'
import './plugins/vueGoogleMaps'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

import ress from './plugins/ress'
Vue.filter('ress', function (img) {
  const deviceType = store.state.deviceType
  return ress.route(img, deviceType)
})

Vue.filter('background', function (route) {
  if (!route) return ''
  return `background-image: url('${route}')`
})

Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
