<template lang="pug">
    footer.main_footer.font_smooth
        .pattern.black
        .column
            .logo
                svg(viewBox="0 0 113.6 98.3")
                    use(xlink:href="#icon-logo_vertical")
            .split
                div
                    .split-wrapper
                        .grid.grid-split
                            div
                                .wrapper
                                    .margin-bottom--lg
                                        span.title Menú
                                    ul.quicklinks
                                        li
                                            router-link(to="/" alt="Inicio")
                                                span Inicio
                                        li
                                            router-link(to="/servicios" alt="Servicios")
                                                span Servicios
                                        li
                                            router-link(to="/proyectos" alt="Proyectos")
                                                span Proyectos
                                        li
                                            router-link(to="/nosotros" alt="Nosotros")
                                                span Nosotros
                                        li
                                            router-link(to="/contacto" alt="Contacto")
                                                span Contacto
                            div
                                .wrapper
                                    .margin-bottom--lg
                                        span.title Servicios
                                    ul.quicklinks
                                        li(v-for="service in allServices")
                                            slug-link(
                                                route="modal_service"
                                                :name="service.name"
                                                :pk="service.pk"
                                            )
                                                span {{ service.name }}
                div
                    .split-wrapper
                        .grid.grid-split
                            div
                                .wrapper
                                    .margin-bottom--xxl
                                        .margin-bottom--lg
                                            span.title Contáctanos
                                        ul.quicklinks
                                            li
                                                a(href="tel:4433231835" alt="Teléfono")
                                                    span (443) 323 1835
                                            li
                                                a(href="tel:4433234152" alt="Teléfono")
                                                    span (443) 323 4152
                                            li
                                                a(href="mailto:contacto@lafragua.mx" alt="Correo Electrónico")
                                                    span contacto@lafragua.mx
                                    .margin-bottom--lg
                                        span.title Alianzas
                                    ul.quicklinks
                                        li
                                            a(href="https://gruashercules.mx/" alt="Grúas Hércules" target="_blank")
                                                span Grúas Hércules
                                        li
                                            a(href="http://prefabricaceros.com/" alt="Prefabricaceros" target="_blank")
                                                span Prefabricaceros
                            div
                                .wrapper
                                    .margin-bottom--lg
                                        span.title Síguenos
                                    ul.quicklinks
                                        li
                                            a(href="https://web.facebook.com/estructurasygruaslafragua" alt="Facebook" target="_blank")
                                                span Facebook
                                        li
                                            a(href="https://www.youtube.com/channel/UCs4kq8bKDmirBKsuNzu9_Zg" alt="YouTube" target="_blank")
                                                span YouTube
        .copyright
            .column.flex_content-horizontal.jc_space-between
                div
                  span.text--xs &copy La Fragua {{ copyright() }}
                .flex_content-horizontal.ai_center
                  span.inline-block.margin-right--sm.text--xs Creado por
                  a(href="https://neodiko.com" alt="Neodiko" target="_blank").inline-block
                    span.off_screen Neodiko, experiencias únicas para la web.
                    LogoNeodiko(style="width: 80px;")
</template>

<script>
import SlugLink from '@/components/SlugLink'
import LogoNeodiko from '@/components/LogoNeodiko.vue'

export default {
    name: 'include_mainFooter',
    components: {
        SlugLink,
        LogoNeodiko
    },
    data() {
        return {
            creationYear: 2018
        }
    },
    methods: {
        copyright() {
            const today = new Date()
            const currentYear = today.getFullYear()
            if (this.creationYear >= currentYear) return this.creationYear
            return `${this.creationYear} - ${currentYear}`
        }
    },
    computed: {
        allServices() {
            return this.$store.getters.allServices
        }
    }
}
</script>