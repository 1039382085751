<template lang="pug">
    .curtain
        .column.flex_content-vertical.ai_center.jc_center
            .anime_logo
                svg(viewBox="0 0 864.3 330.3").logo-horizontal
                    title La Fragua, Estructuras y Grúas
                    g
                        g.st0
                            g
                                path(d="M124.5,48.1c-9.8,5-19.1,11.1-27.6,18.1c-13.5,11-24.9,24.5-33.1,39.7c-3.6,6.8-6.5,13.9-8.5,21.3l5.6,0c2.8-5.9,6.2-11.6,10-16.9c9.3-13.2,21.1-24.3,34.1-33.5c10.5-7.4,21.9-13.4,33.8-18L124.5,48.1z")
                                path(d="M323.7,106.7c-8.1-15.3-19.5-28.8-32.9-39.9c-26.7-22.4-61.5-35.3-96.9-35.6c-21.4,0.1-41.4,4.4-60.8,13l15.6,11.1c15-4.8,29.4-6.9,45.1-7c31.4,0.3,62.7,10.6,88.7,29c12.9,9.3,24.7,20.5,33.9,33.7c3.8,5.3,7.1,11,9.9,16.9l5.7,0C330.1,120.7,327.3,113.5,323.7,106.7z")
                                path(d="M190.4,141.4l-43.2,31.9c-3.8,2.8-6,7.2-6,11.9l-0.1,54l20.9,0.1l0.1-49c0-3.5,1.3-6.9,3.6-9.6l29.5-34.5L190.4,141.4z")
                                path(d="M155.6,106.3l-67.1,32.4c-5.1,2.5-8.4,7.7-8.4,13.4L79.8,239l32.7,0.1l0.2-73.9c0-4.3,1.9-8.3,5.1-11.2l45.5-39.8L155.6,106.3z")
                                path(d="M115,39.5l-5.3,5.2L210.2,147c2.7,2.8,4.3,6.6,4.3,10.5l-0.2,81.8l30.1,0.1l0.3-96c0-4.7-2.2-9.2-6-12L115,39.5z")
                                path(d="M200.5,81.8l-5.7,5.4l83.7,59.4c3.9,2.8,6.2,7.3,6.2,12.1v0l-0.2,80.8l27.5,0.1l0.2-88.5c0-5.5-3-10.6-7.8-13.2L200.5,81.8z")
                                path(d="M33.7,238.2c27-4.8,54.1-7.2,81.1-8.9c13.5-1,27-1.2,40.6-1.8c13.5-0.3,27-0.3,40.6-0.5c13.5,0.3,27,0.3,40.5,0.8c13.5,0.7,27,0.9,40.5,2.1c27,1.8,54.1,4.4,81.1,9.3l0,1.4l-162.2,0.2l-162.2-1.1L33.7,238.2z")
                            g
                                path(d="M416,176.4l0,9.5c0,1.3-0.7,2-2,2l-37-0.1c-1.3,0-2-0.7-2-2l0.1-53.2c0-1.3,0.7-2,2-2l11.6,0c1.3,0,2,0.7,2,2l-0.1,41.6l23.4,0.1C415.3,174.4,416,175.1,416,176.4z")
                                path(d="M473.7,188.2l-11.8,0c-1.1,0-2-0.5-2.4-1.6l-3.2-8.1l-20.6-0.1l-3.3,8.1c-0.4,1.1-1.2,1.5-2.4,1.5l-11.6,0c-1.5,0-2.2-0.9-1.5-2.4l24.2-53.3c0.5-1.1,1.2-1.5,2.4-1.5l5.9,0c1.1,0,1.9,0.5,2.4,1.6l23.6,53.4C475.9,187.3,475.2,188.2,473.7,188.2z M439.4,166.7l13.1,0l-6.5-15.6L439.4,166.7z")
                                path(d="M538,133.1l0,10.4c0,1.3-0.7,2-2,2l-23.5-0.1l0,11.3l19.6,0.1c1.3,0,2,0.7,2,2l0,10.4c0,1.3-0.7,2-2,2l-19.6-0.1l0,14.9c0,1.3-0.7,2-2,2l-11.6,0c-1.3,0-2-0.7-2-2l0.1-53.2c0-1.3,0.7-2,2-2l37.2,0.1C537.2,131.1,538,131.8,538,133.1z")
                                path(d="M592.4,188.5l-13.1,0c-1.1,0-2-0.4-2.5-1.3l-8.5-13.4l-6.6,0l0,12.6c0,1.3-0.7,2-2,2l-11.2,0c-1.3,0-2-0.7-2-2l0.1-53.2c0-1.3,0.7-2,2-2l22,0.1c14.7,0,23.4,9.4,23.4,21.7c0,8-4.1,14.3-10.9,17.8l10.6,15.3C594.7,187.4,594,188.5,592.4,188.5z M561.7,160.8l7.6,0c5.6,0,9.1-3.6,9.1-8.1c0-4.8-3.3-8.6-9.3-8.6l-7.3,0L561.7,160.8z")
                                path(d="M655.3,188.6l-11.8,0c-1.1,0-2-0.5-2.4-1.6l-3.2-8.1l-20.6-0.1L614,187c-0.4,1.1-1.2,1.5-2.4,1.5l-11.6,0c-1.5,0-2.2-0.9-1.5-2.4l24.2-53.3c0.5-1.1,1.2-1.5,2.4-1.5l5.9,0c1.1,0,1.9,0.5,2.4,1.6l23.6,53.4C657.5,187.8,656.9,188.7,655.3,188.6z M621.1,167.2l13.1,0l-6.5-15.6L621.1,167.2z")
                                path(d="M711.9,160.3l-0.1,20.5c0,0.9-0.3,1.7-0.9,2.1c-2.4,2-9.6,6.6-22.2,6.5c-17.9,0-30.6-12.9-30.6-29.2c0-16.3,12.8-29.6,31.4-29.6c9.8,0,15.9,2.6,19.5,4.7c1.1,0.7,1.2,1.6,0.6,2.8L705,147c-0.7,1.1-1.6,1.5-2.8,0.8c-2.9-1.6-6.2-2.8-11.8-2.8c-9.5,0-16,5.8-16,15.4c0,9.2,6.8,15.3,15.4,15.3c2.7,0,5.5-0.3,7.8-1.4l0-14c0-1.3,0.7-2,2-2l10.2,0C711.2,158.2,711.9,159,711.9,160.3z")
                                path(d="M721,163.2l0.1-29.6c0-1.3,0.7-2,2-2l11.5,0c1.3,0,2,0.7,2,2l-0.1,30.8c0,5.3,2.7,11.3,10.7,11.4c8.1,0,11-6,11-11.3l0.1-30.8c0-1.3,0.7-2,2-2l11.5,0c1.3,0,2,0.7,2,2l-0.1,29.6c0,17.4-10.7,26.4-26.6,26.3C731.5,189.6,720.9,180.6,721,163.2z")
                                path(d="M834,189.1l-11.8,0c-1.1,0-2-0.5-2.4-1.6l-3.2-8.1l-20.6-0.1l-3.3,8.1c-0.4,1.1-1.2,1.5-2.4,1.5l-11.6,0c-1.5,0-2.2-0.9-1.5-2.4l24.2-53.3c0.5-1.1,1.2-1.5,2.4-1.5l5.9,0c1.1,0,1.9,0.5,2.4,1.6l23.6,53.4C836.2,188.2,835.5,189.1,834,189.1z M799.7,167.7l13.1,0l-6.5-15.6L799.7,167.7z")
                        g.st1
                            path(d="M437.2,227.4l0,2.5c0,0.3-0.2,0.5-0.5,0.5l-10.7,0c-0.3,0-0.5-0.2-0.5-0.5l0-15.5c0-0.3,0.2-0.5,0.5-0.5l10.6,0c0.3,0,0.5,0.2,0.5,0.5l0,2.5c0,0.3-0.2,0.5-0.5,0.5l-7.1,0l0,3l6.2,0c0.3,0,0.5,0.2,0.5,0.5l0,2.5c0,0.3-0.2,0.5-0.5,0.5l-6.2,0l0,3.1l7.3,0C437.1,226.9,437.2,227.1,437.2,227.4z")
                            path(d="M444,228.8c-0.2-0.2-0.3-0.5-0.1-0.7l1.4-2.2c0.1-0.2,0.4-0.3,0.7-0.1c1.4,1.1,2.9,1.5,4.5,1.5c1.1,0,2-0.4,2-1.5c0-1.1-0.8-1.5-3.1-2.2c-2.6-0.8-4.9-1.8-4.9-4.8c0-3.1,2.5-5,6.2-5c2.2,0,4,0.6,5.3,1.5c0.3,0.2,0.3,0.4,0.1,0.7l-1.3,2.1c-0.2,0.3-0.4,0.3-0.7,0.2c-1-0.6-2.2-1-3.5-1c-1.2,0-2.2,0.3-2.2,1.3c0,1,1,1.2,3.2,1.9c2.4,0.8,4.8,1.8,4.8,5.1c0,3.1-2.3,5.1-6,5.1C447.5,230.6,445.5,229.9,444,228.8z")
                            path(d="M476.8,214.5l0,2.5c0,0.3-0.2,0.5-0.5,0.5l-4.9,0l0,12.5c0,0.3-0.2,0.5-0.5,0.5l-3,0c-0.3,0-0.5-0.2-0.5-0.5l0-12.5l-4.9,0c-0.3,0-0.5-0.2-0.5-0.5l0-2.5c0-0.3,0.2-0.5,0.5-0.5l13.7,0C476.6,214.1,476.8,214.2,476.8,214.5z")
                            path(d="M496.3,230.5l-3.5,0c-0.2,0-0.4-0.1-0.6-0.3l-2.8-4.1l0,0l-2,0l0,3.9c0,0.3-0.2,0.5-0.5,0.5l-3,0c-0.3,0-0.5-0.2-0.5-0.5l0-15.5c0-0.3,0.2-0.5,0.5-0.5l5.6,0c4.1,0,6.8,2.5,6.8,6.1c0,2.3-1.2,4.1-3.1,5.1l3.3,4.7C496.8,230.3,496.6,230.5,496.3,230.5z M487.4,222.7l1.9,0c1.8,0,3.1-1,3.1-2.5c0-1.6-1.2-2.7-3.1-2.7l-1.9,0L487.4,222.7z")
                            path(d="M503.3,223.3l0-8.7c0-0.3,0.2-0.5,0.5-0.5l3,0c0.3,0,0.5,0.2,0.5,0.5l0,9c0,1.8,1,3.6,3.5,3.6c2.5,0,3.5-1.8,3.5-3.6l0-9c0-0.3,0.2-0.5,0.5-0.5l3,0c0.3,0,0.5,0.2,0.5,0.5l0,8.7c0,4.9-3,7.4-7.5,7.4C506.3,230.7,503.3,228.2,503.3,223.3z")
                            path(d="M525.4,222.4c0-4.7,3.5-8.4,8.8-8.4c2.4,0,4.2,0.8,5.1,1.4c0.2,0.2,0.3,0.4,0.1,0.7l-1.3,2.3c-0.1,0.3-0.4,0.3-0.6,0.2c-0.6-0.3-1.7-0.8-3.2-0.8c-3,0-4.9,1.8-4.9,4.7c0,2.9,1.9,4.7,4.9,4.7c1.5,0,2.6-0.5,3.2-0.8c0.3-0.1,0.5-0.1,0.6,0.2l1.3,2.3c0.1,0.3,0.1,0.5-0.1,0.7c-0.7,0.5-2.4,1.4-5.1,1.4C528.8,230.8,525.3,227.1,525.4,222.4z")
                            path(d="M559.6,214.8l0,2.5c0,0.3-0.2,0.5-0.5,0.5l-4.9,0l0,12.5c0,0.3-0.2,0.5-0.5,0.5l-3,0c-0.3,0-0.5-0.2-0.5-0.5l0-12.5l-4.9,0c-0.3,0-0.5-0.2-0.5-0.5l0-2.5c0-0.3,0.2-0.5,0.5-0.5l13.7,0C559.5,214.3,559.6,214.5,559.6,214.8z")
                            path(d="M566.1,223.5l0-8.7c0-0.3,0.2-0.5,0.5-0.5l3,0c0.3,0,0.5,0.2,0.5,0.5l0,9c0,1.8,1,3.6,3.5,3.6c2.5,0,3.5-1.8,3.5-3.6l0-9c0-0.3,0.2-0.5,0.5-0.5l3,0c0.3,0,0.5,0.2,0.5,0.5l0,8.7c0,4.9-3,7.4-7.5,7.4C569,230.9,566.1,228.4,566.1,223.5z")
                            path(d="M601.8,230.8l-3.5,0c-0.2,0-0.4-0.1-0.6-0.3l-2.8-4.1l0,0l-2,0l0,3.9c0,0.3-0.2,0.5-0.5,0.5l-3,0c-0.3,0-0.5-0.2-0.5-0.5l0-15.5c0-0.3,0.2-0.5,0.5-0.5l5.6,0c4.1,0,6.8,2.5,6.8,6.1c0,2.3-1.2,4.1-3.1,5.1l3.3,4.7C602.3,230.6,602.2,230.8,601.8,230.8z M593,223l1.9,0c1.8,0,3.1-1,3.1-2.5c0-1.6-1.2-2.7-3.1-2.7l-1.9,0L593,223z")
                            path(d="M623.7,230.9l-3.1,0c-0.3,0-0.4-0.1-0.5-0.4l-1-2.5l-6.3,0l-1.1,2.5c-0.1,0.2-0.3,0.4-0.5,0.4l-3.1,0c-0.3,0-0.5-0.2-0.4-0.5l6.9-15.5c0.1-0.2,0.3-0.3,0.6-0.3l1.7,0c0.3,0,0.5,0.1,0.6,0.4l6.8,15.5C624.2,230.6,624,230.9,623.7,230.9z M613.9,224.6l3.8,0l-1.9-4.6L613.9,224.6z")
                            path(d="M629.7,229.3c-0.2-0.2-0.3-0.5-0.1-0.7l1.4-2.2c0.1-0.2,0.4-0.3,0.7-0.1c1.4,1.1,2.9,1.5,4.4,1.5c1.1,0,2-0.4,2-1.5c0-1.1-0.8-1.5-3.1-2.2c-2.6-0.8-4.9-1.8-4.9-4.8c0-3.1,2.5-5,6.2-5c2.2,0,4,0.6,5.3,1.5c0.3,0.2,0.3,0.4,0.1,0.7l-1.3,2.1c-0.2,0.3-0.4,0.3-0.7,0.2c-1-0.6-2.2-1-3.5-1c-1.2,0-2.2,0.3-2.2,1.3c0,1,1,1.2,3.2,1.9c2.4,0.8,4.8,1.8,4.8,5.1c0,3.1-2.3,5.1-6,5.1C633.2,231.1,631.2,230.4,629.7,229.3z")
                            path(d="M672.5,215.2l-5.7,9.4l0,5.9c0,0.3-0.2,0.5-0.5,0.5l-3,0c-0.3,0-0.5-0.2-0.5-0.5l0-5.9l-5.6-9.5c-0.2-0.3,0-0.6,0.3-0.6l3.7,0c0.3,0,0.4,0.1,0.6,0.3l3.2,5.8l3.2-5.8c0.1-0.2,0.3-0.3,0.6-0.3l3.4,0C672.5,214.6,672.6,214.9,672.5,215.2z")
                            path(d="M702.7,223.1l0,5.8c0,0.3-0.1,0.4-0.3,0.6c-0.7,0.6-2.6,1.8-6,1.8c-5.2,0-8.8-3.7-8.8-8.4c0-4.7,3.6-8.4,8.9-8.4c2.9,0,4.5,0.9,5.4,1.4c0.3,0.2,0.3,0.4,0.2,0.6l-1.3,2.3c-0.1,0.3-0.4,0.3-0.6,0.2c-0.7-0.3-1.8-0.8-3.5-0.8c-2.9,0-5,1.8-5,4.7c0,2.9,2.2,4.9,5,4.9c0.9,0,1.7-0.2,2.4-0.5l0-4.2c0-0.3,0.2-0.5,0.5-0.5l2.7,0C702.5,222.6,702.7,222.7,702.7,223.1z")
                            path(d="M723.1,231.1l-3.5,0c-0.2,0-0.4-0.1-0.6-0.3l-2.8-4.1l0,0l-2,0l0,3.9c0,0.3-0.2,0.5-0.5,0.5l-3,0c-0.3,0-0.5-0.2-0.5-0.5l0-15.5c0-0.3,0.2-0.5,0.5-0.5l5.6,0c4.1,0,6.8,2.5,6.8,6.1c0,2.3-1.2,4.1-3.1,5.1l3.3,4.7C723.6,230.9,723.4,231.1,723.1,231.1z M714.2,223.3l1.9,0c1.8,0,3.1-1,3.1-2.5c0-1.6-1.2-2.7-3.1-2.7l-1.9,0L714.2,223.3z")
                            path(d="M730.1,223.9l0-8.7c0-0.3,0.2-0.5,0.5-0.5l3,0c0.3,0,0.5,0.2,0.5,0.5l0,9c0,1.8,1,3.6,3.5,3.6c2.5,0,3.5-1.8,3.5-3.6l0-9c0-0.3,0.2-0.5,0.5-0.5l3,0c0.3,0,0.5,0.2,0.5,0.5l0,8.7c0,4.9-3,7.4-7.5,7.4C733.1,231.4,730.1,228.8,730.1,223.9z M734.7,213.3l-0.5-1.4c-0.1-0.3,0-0.5,0.2-0.6l4.9-2.3c0.3-0.1,0.5-0.1,0.6,0.2l0.9,2.3c0.1,0.3,0,0.5-0.3,0.6l-5.3,1.5C735,213.6,734.8,213.5,734.7,213.3z")
                            path(d="M766.9,231.2l-3.1,0c-0.3,0-0.4-0.1-0.5-0.4l-1-2.5l-6.3,0l-1.1,2.5c-0.1,0.2-0.3,0.4-0.5,0.4l-3.1,0c-0.3,0-0.5-0.2-0.4-0.5l6.9-15.5c0.1-0.2,0.3-0.3,0.6-0.3l1.7,0c0.3,0,0.5,0.1,0.6,0.4l6.8,15.5C767.4,231,767.2,231.2,766.9,231.2z M757.1,225l3.8,0l-1.9-4.6L757.1,225z")
                            path(d="M772.9,229.7c-0.2-0.2-0.3-0.5-0.1-0.7l1.4-2.2c0.1-0.2,0.4-0.3,0.7-0.1c1.4,1.1,2.9,1.5,4.5,1.5c1.1,0,2-0.4,2-1.5c0-1.1-0.8-1.5-3.1-2.2c-2.6-0.8-4.9-1.8-4.9-4.8c0-3.1,2.5-5,6.2-5c2.2,0,4,0.6,5.3,1.5c0.3,0.2,0.3,0.4,0.1,0.7l-1.3,2.1c-0.2,0.3-0.4,0.3-0.7,0.2c-1-0.6-2.2-1-3.5-1c-1.2,0-2.2,0.3-2.2,1.3c0,1,1,1.2,3.2,1.9c2.4,0.8,4.8,1.8,4.8,5.1c0,3.1-2.3,5.1-6,5.1C776.4,231.5,774.5,230.8,772.9,229.7z")
                svg(viewBox="0 0 113.6 108.7").logo-vertical
                    title La Fragua, Estructuras y Grúas
                    g
                        g.st0
                            g
                                path(d="M32.2,5.8c-3.4,1.7-6.6,3.8-9.5,6.3c-4.6,3.8-8.6,8.4-11.4,13.7c-1.2,2.3-2.2,4.8-2.9,7.3l1.9,0c1-2,2.1-4,3.4-5.8c3.2-4.5,7.3-8.4,11.8-11.6c3.6-2.5,7.6-4.6,11.7-6.2L32.2,5.8z")
                                path(d="M100.9,26c-2.8-5.3-6.7-9.9-11.3-13.8C80.3,4.5,68.3,0.1,56.2,0c-7.4,0-14.3,1.5-20.9,4.5l5.4,3.8c5.2-1.7,10.1-2.4,15.5-2.4C67,6,77.7,9.6,86.7,15.9c4.5,3.2,8.5,7.1,11.7,11.6c1.3,1.8,2.4,3.8,3.4,5.8l2,0C103.1,30.8,102.1,28.4,100.9,26z")
                                path(d="M54.9,38L40,49c-1.3,1-2.1,2.5-2.1,4.1l-0.1,18.6l7.2,0l0-16.9c0-1.2,0.4-2.4,1.2-3.3l10.2-11.9L54.9,38z")
                                path(d="M42.9,25.9L19.8,37.1c-1.8,0.9-2.9,2.6-2.9,4.6l-0.1,29.9l11.3,0l0.1-25.5c0-1.5,0.6-2.9,1.8-3.8l15.7-13.7L42.9,25.9z")
                                path(d="M28.9,2.9l-1.8,1.8l34.6,35.2c0.9,1,1.5,2.3,1.5,3.6l-0.1,28.2l10.4,0l0.1-33.1c0-1.6-0.8-3.2-2.1-4.1L28.9,2.9z")
                                path(d="M58.4,17.5l-2,1.8l28.8,20.5c1.3,1,2.1,2.5,2.1,4.2v0l-0.1,27.8l9.5,0l0.1-30.5c0-1.9-1-3.6-2.7-4.5L58.4,17.5z")
                                path(d="M0.9,71.3c9.3-1.6,18.6-2.5,28-3.1c4.7-0.4,9.3-0.4,14-0.6c4.7-0.1,9.3-0.1,14-0.2c4.7,0.1,9.3,0.1,14,0.3c4.7,0.2,9.3,0.3,14,0.7c9.3,0.6,18.6,1.5,27.9,3.2l0,0.5l-55.9,0.1L0.9,71.8L0.9,71.3z")
                            g
                                path(d="M10.1,95.1l0,2.4c0,0.3-0.2,0.5-0.5,0.5l-9.1,0c-0.3,0-0.5-0.2-0.5-0.5l0-13.1c0-0.3,0.2-0.5,0.5-0.5l2.9,0c0.3,0,0.5,0.2,0.5,0.5l0,10.3l5.8,0C10,94.6,10.1,94.8,10.1,95.1z")
                                path(d="M24.3,98l-2.9,0c-0.3,0-0.5-0.1-0.6-0.4l-0.8-2l-5.1,0l-0.8,2c-0.1,0.3-0.3,0.4-0.6,0.4l-2.9,0c-0.4,0-0.5-0.2-0.4-0.6l6-13.1c0.1-0.3,0.3-0.4,0.6-0.4l1.5,0c0.3,0,0.5,0.1,0.6,0.4l5.8,13.2C24.9,97.8,24.7,98,24.3,98zM15.9,92.7l3.2,0l-1.6-3.8L15.9,92.7z")
                                path(d="M40.2,84.4l0,2.6c0,0.3-0.2,0.5-0.5,0.5l-5.8,0l0,2.8l4.8,0c0.3,0,0.5,0.2,0.5,0.5l0,2.6c0,0.3-0.2,0.5-0.5,0.5l-4.8,0l0,3.7c0,0.3-0.2,0.5-0.5,0.5l-2.9,0c-0.3,0-0.5-0.2-0.5-0.5l0-13.1c0-0.3,0.2-0.5,0.5-0.5l9.2,0C40,83.9,40.2,84.1,40.2,84.4z")
                                path(d="M53.6,98l-3.2,0c-0.3,0-0.5-0.1-0.6-0.3l-2.1-3.3l-1.6,0l0,3.1c0,0.3-0.2,0.5-0.5,0.5l-2.8,0c-0.3,0-0.5-0.2-0.5-0.5l0-13.1c0-0.3,0.2-0.5,0.5-0.5l5.4,0c3.6,0,5.8,2.3,5.8,5.4c0,2-1,3.5-2.7,4.4l2.6,3.8C54.2,97.8,54,98.1,53.6,98z M46,91.2l1.9,0c1.4,0,2.3-0.9,2.3-2c0-1.2-0.8-2.1-2.3-2.1l-1.8,0L46,91.2z")
                                path(d="M69.1,98.1l-2.9,0c-0.3,0-0.5-0.1-0.6-0.4l-0.8-2l-5.1,0l-0.8,2c-0.1,0.3-0.3,0.4-0.6,0.4l-2.9,0c-0.4,0-0.5-0.2-0.4-0.6l6-13.1c0.1-0.3,0.3-0.4,0.6-0.4l1.5,0c0.3,0,0.5,0.1,0.6,0.4l5.8,13.2C69.7,97.9,69.5,98.1,69.1,98.1zM60.7,92.8l3.2,0L62.3,89L60.7,92.8z")
                                path(d="M83.1,91.1l0,5.1c0,0.2-0.1,0.4-0.2,0.5c-0.6,0.5-2.4,1.6-5.5,1.6c-4.4,0-7.5-3.2-7.5-7.2c0-4,3.2-7.3,7.8-7.3c2.4,0,3.9,0.6,4.8,1.2c0.3,0.2,0.3,0.4,0.2,0.7l-1.2,2.2c-0.2,0.3-0.4,0.4-0.7,0.2c-0.7-0.4-1.5-0.7-2.9-0.7c-2.4,0-3.9,1.4-3.9,3.8c0,2.3,1.7,3.8,3.8,3.8c0.7,0,1.3-0.1,1.9-0.3l0-3.5c0-0.3,0.2-0.5,0.5-0.5l2.5,0C82.9,90.6,83.1,90.8,83.1,91.1z")
                                path(d="M85.3,91.8l0-7.3c0-0.3,0.2-0.5,0.5-0.5l2.8,0c0.3,0,0.5,0.2,0.5,0.5l0,7.6c0,1.3,0.7,2.8,2.6,2.8c2,0,2.7-1.5,2.7-2.8l0-7.6c0-0.3,0.2-0.5,0.5-0.5l2.8,0c0.3,0,0.5,0.2,0.5,0.5l0,7.3c0,4.3-2.6,6.5-6.6,6.5C87.9,98.3,85.3,96.1,85.3,91.8z")
                                path(d="M113.2,98.2l-2.9,0c-0.3,0-0.5-0.1-0.6-0.4l-0.8-2l-5.1,0l-0.8,2c-0.1,0.3-0.3,0.4-0.6,0.4l-2.9,0c-0.4,0-0.5-0.2-0.4-0.6l6-13.1c0.1-0.3,0.3-0.4,0.6-0.4l1.5,0c0.3,0,0.5,0.1,0.6,0.4l5.8,13.2C113.7,98,113.6,98.2,113.2,98.2zM104.7,92.9l3.2,0l-1.6-3.8L104.7,92.9z")
                        g.st1
                            path(d="M15.4,107.6l0,0.6c0,0.1,0,0.1-0.1,0.1l-2.6,0c-0.1,0-0.1,0-0.1-0.1l0-3.8c0-0.1,0-0.1,0.1-0.1l2.6,0c0.1,0,0.1,0,0.1,0.1l0,0.6c0,0.1,0,0.1-0.1,0.1l-1.8,0l0,0.7l1.5,0c0.1,0,0.1,0,0.1,0.1l0,0.6c0,0.1,0,0.1-0.1,0.1l-1.5,0l0,0.8l1.8,0C15.3,107.5,15.4,107.6,15.4,107.6z")
                            path(d="M17,108c-0.1,0-0.1-0.1,0-0.2l0.3-0.5c0-0.1,0.1-0.1,0.2,0c0.4,0.3,0.7,0.4,1.1,0.4c0.3,0,0.5-0.1,0.5-0.4c0-0.3-0.2-0.4-0.8-0.5c-0.6-0.2-1.2-0.4-1.2-1.2c0-0.8,0.6-1.2,1.5-1.2c0.5,0,1,0.1,1.3,0.4c0.1,0,0.1,0.1,0,0.2l-0.3,0.5c0,0.1-0.1,0.1-0.2,0c-0.3-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.5,0.1-0.5,0.3c0,0.2,0.2,0.3,0.8,0.5c0.6,0.2,1.2,0.4,1.2,1.3c0,0.8-0.6,1.3-1.5,1.2C17.9,108.4,17.4,108.3,17,108z")
                            path(d="M25.1,104.5l0,0.6c0,0.1,0,0.1-0.1,0.1l-1.2,0l0,3.1c0,0.1,0,0.1-0.1,0.1l-0.7,0c-0.1,0-0.1,0-0.1-0.1l0-3.1l-1.2,0c-0.1,0-0.1,0-0.1-0.1l0-0.6c0-0.1,0-0.1,0.1-0.1l3.4,0C25.1,104.4,25.1,104.4,25.1,104.5z")
                            path(d="M29.9,108.4l-0.9,0c-0.1,0-0.1,0-0.1-0.1l-0.7-1l0,0l-0.5,0l0,1c0,0.1,0,0.1-0.1,0.1l-0.7,0c-0.1,0-0.1,0-0.1-0.1l0-3.8c0-0.1,0-0.1,0.1-0.1l1.4,0c1,0,1.7,0.6,1.7,1.5c0,0.6-0.3,1-0.8,1.2l0.8,1.2C30,108.4,30,108.4,29.9,108.4z M27.7,106.5l0.5,0c0.5,0,0.8-0.2,0.8-0.6c0-0.4-0.3-0.7-0.8-0.7l-0.5,0L27.7,106.5z")
                            path(d="M31.7,106.6l0-2.1c0-0.1,0-0.1,0.1-0.1l0.7,0c0.1,0,0.1,0,0.1,0.1l0,2.2c0,0.4,0.2,0.9,0.9,0.9c0.6,0,0.9-0.4,0.9-0.9l0-2.2c0-0.1,0-0.1,0.1-0.1l0.7,0c0.1,0,0.1,0,0.1,0.1l0,2.1c0,1.2-0.7,1.8-1.8,1.8C32.4,108.5,31.6,107.8,31.7,106.6z")
                            path(d="M37.1,106.4c0-1.2,0.9-2.1,2.2-2.1c0.6,0,1,0.2,1.3,0.3c0.1,0,0.1,0.1,0,0.2l-0.3,0.6c0,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.4-0.2-0.8-0.2c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.4,0,0.6-0.1,0.8-0.2c0.1,0,0.1,0,0.2,0l0.3,0.6c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.6,0.3-1.3,0.3C37.9,108.5,37.1,107.6,37.1,106.4z")
                            path(d="M45.5,104.5l0,0.6c0,0.1,0,0.1-0.1,0.1l-1.2,0l0,3.1c0,0.1,0,0.1-0.1,0.1l-0.7,0c-0.1,0-0.1,0-0.1-0.1l0-3.1l-1.2,0c-0.1,0-0.1,0-0.1-0.1l0-0.6c0-0.1,0-0.1,0.1-0.1l3.4,0C45.5,104.4,45.5,104.5,45.5,104.5z")
                            path(d="M47.1,106.7l0-2.1c0-0.1,0-0.1,0.1-0.1l0.7,0c0.1,0,0.1,0,0.1,0.1l0,2.2c0,0.4,0.2,0.9,0.9,0.9c0.6,0,0.9-0.4,0.9-0.9l0-2.2c0-0.1,0-0.1,0.1-0.1l0.7,0c0.1,0,0.1,0,0.1,0.1l0,2.1c0,1.2-0.7,1.8-1.8,1.8C47.9,108.5,47.1,107.9,47.1,106.7z")
                            path(d="M55.9,108.5l-0.9,0c-0.1,0-0.1,0-0.1-0.1l-0.7-1l0,0l-0.5,0l0,1c0,0.1,0,0.1-0.1,0.1l-0.7,0c-0.1,0-0.1,0-0.1-0.1l0-3.8c0-0.1,0-0.1,0.1-0.1l1.4,0c1,0,1.7,0.6,1.7,1.5c0,0.6-0.3,1-0.8,1.2l0.8,1.2C56.1,108.4,56,108.5,55.9,108.5z M53.7,106.6l0.5,0c0.5,0,0.8-0.2,0.8-0.6c0-0.4-0.3-0.7-0.8-0.7l-0.5,0L53.7,106.6z")
                            path(d="M61.3,108.5l-0.8,0c-0.1,0-0.1,0-0.1-0.1l-0.3-0.6l-1.6,0l-0.3,0.6c0,0.1-0.1,0.1-0.1,0.1l-0.8,0c-0.1,0-0.1-0.1-0.1-0.1l1.7-3.8c0-0.1,0.1-0.1,0.1-0.1l0.4,0c0.1,0,0.1,0,0.1,0.1l1.7,3.8C61.4,108.4,61.4,108.5,61.3,108.5zM58.9,107l0.9,0l-0.5-1.1L58.9,107z")
                            path(d="M62.8,108.1c-0.1,0-0.1-0.1,0-0.2l0.3-0.5c0-0.1,0.1-0.1,0.2,0c0.4,0.3,0.7,0.4,1.1,0.4c0.3,0,0.5-0.1,0.5-0.4c0-0.3-0.2-0.4-0.8-0.5c-0.6-0.2-1.2-0.4-1.2-1.2c0-0.8,0.6-1.2,1.5-1.2c0.5,0,1,0.1,1.3,0.4c0.1,0,0.1,0.1,0,0.2l-0.3,0.5c0,0.1-0.1,0.1-0.2,0c-0.3-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.5,0.1-0.5,0.3c0,0.2,0.2,0.3,0.8,0.5c0.6,0.2,1.2,0.4,1.2,1.3c0,0.8-0.6,1.3-1.5,1.2C63.7,108.6,63.2,108.4,62.8,108.1z")
                            path(d="M73.4,104.6l-1.4,2.3l0,1.5c0,0.1,0,0.1-0.1,0.1l-0.7,0c-0.1,0-0.1,0-0.1-0.1l0-1.5l-1.4-2.3c0-0.1,0-0.1,0.1-0.1l0.9,0c0.1,0,0.1,0,0.1,0.1l0.8,1.4l0.8-1.4c0-0.1,0.1-0.1,0.1-0.1l0.8,0C73.4,104.5,73.4,104.6,73.4,104.6z")
                            path(d="M80.8,106.6l0,1.4c0,0.1,0,0.1-0.1,0.1c-0.2,0.1-0.6,0.4-1.5,0.4c-1.3,0-2.2-0.9-2.2-2.1c0-1.2,0.9-2.1,2.2-2.1c0.7,0,1.1,0.2,1.3,0.3c0.1,0,0.1,0.1,0,0.2l-0.3,0.6c0,0.1-0.1,0.1-0.2,0c-0.2-0.1-0.5-0.2-0.9-0.2c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.2,0,0.4,0,0.6-0.1l0-1c0-0.1,0-0.1,0.1-0.1l0.7,0C80.8,106.5,80.8,106.5,80.8,106.6z")
                            path(d="M85.8,108.6l-0.9,0c-0.1,0-0.1,0-0.1-0.1l-0.7-1l0,0l-0.5,0l0,1c0,0.1,0,0.1-0.1,0.1l-0.7,0c-0.1,0-0.1,0-0.1-0.1l0-3.8c0-0.1,0-0.1,0.1-0.1l1.4,0c1,0,1.7,0.6,1.7,1.5c0,0.6-0.3,1-0.8,1.2l0.8,1.2C86,108.5,85.9,108.6,85.8,108.6z M83.6,106.6l0.5,0c0.5,0,0.8-0.2,0.8-0.6c0-0.4-0.3-0.7-0.8-0.7l-0.5,0L83.6,106.6z")
                            path(d="M87.6,106.8l0-2.1c0-0.1,0-0.1,0.1-0.1l0.7,0c0.1,0,0.1,0,0.1,0.1l0,2.2c0,0.4,0.2,0.9,0.9,0.9c0.6,0,0.9-0.4,0.9-0.9l0-2.2c0-0.1,0-0.1,0.1-0.1l0.7,0c0.1,0,0.1,0,0.1,0.1l0,2.1c0,1.2-0.7,1.8-1.8,1.8C88.3,108.6,87.6,108,87.6,106.8z M88.7,104.2l-0.1-0.3c0-0.1,0-0.1,0.1-0.1l1.2-0.6c0.1,0,0.1,0,0.1,0.1l0.2,0.6c0,0.1,0,0.1-0.1,0.1l-1.3,0.4C88.8,104.3,88.7,104.2,88.7,104.2z")
                            path(d="M96.6,108.6l-0.8,0c-0.1,0-0.1,0-0.1-0.1l-0.3-0.6l-1.6,0l-0.3,0.6c0,0.1-0.1,0.1-0.1,0.1l-0.8,0c-0.1,0-0.1-0.1-0.1-0.1l1.7-3.8c0-0.1,0.1-0.1,0.1-0.1l0.4,0c0.1,0,0.1,0,0.1,0.1l1.7,3.8C96.8,108.5,96.7,108.6,96.6,108.6zM94.2,107.1l0.9,0l-0.5-1.1L94.2,107.1z")
                            path(d="M98.1,108.2c-0.1,0-0.1-0.1,0-0.2l0.3-0.5c0-0.1,0.1-0.1,0.2,0c0.4,0.3,0.7,0.4,1.1,0.4c0.3,0,0.5-0.1,0.5-0.4c0-0.3-0.2-0.4-0.8-0.5c-0.6-0.2-1.2-0.4-1.2-1.2c0-0.8,0.6-1.2,1.5-1.2c0.5,0,1,0.1,1.3,0.4c0.1,0,0.1,0.1,0,0.2l-0.3,0.5c0,0.1-0.1,0.1-0.2,0c-0.3-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.5,0.1-0.5,0.3c0,0.2,0.2,0.3,0.8,0.5c0.6,0.2,1.2,0.4,1.2,1.3c0,0.8-0.6,1.3-1.5,1.2C99,108.7,98.5,108.5,98.1,108.2z")
                strong.off_screen La Fragua, Estructuras y Grúas
</template>

<script>
import anime from 'animejs'

export default {
    name: 'include_curtain',
    methods: {
        initAnimations() {
            let curtain = this.$el
            let target
            if (screen.width > 414)
                target = curtain.querySelector('.anime_logo svg.logo-horizontal')
            else
                target = curtain.querySelector('.anime_logo svg.logo-vertical')
            let lineDrawing = anime({
                targets: target.querySelectorAll('.st0 path'),
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 500,
                delay: function(el, i) { return i * 100 },
                complete: function(anim) {
                    target.classList.add('filled')
                    setTimeout(() => {
                        curtain.classList.add('close_curtain')
                        document.body.classList.remove('no_scroll')
                    }, 500)
                }
            })
        },
        close() {
            this.$el.classList.add('close_curtain')
            document.body.classList.remove('no_scroll')
        }
    },
    beforeMount() {
        document.body.classList.add('no_scroll')
    },
    mounted() {
        this.initAnimations()
    }
}
</script>